import { useSelector } from 'react-redux'
import { mdiAccountMultipleOutline, mdiAutoFix, mdiChatOutline, mdiLightningBoltOutline } from '@mdi/js'

import { TranslationService as T } from 'shared/services'
import { isNumber } from 'shared/utils'
import { agentsSelectors } from 'modules/agents'
import { packageSelectors } from 'modules/package'
import { shortcutsSelectors } from 'modules/shortcuts'
import { usageSelectors } from 'modules/usage'
import { usageSharedSelectors } from 'modules/usage/sharedSelectors'

import UsageCenterExpirationTag from '../parts/UsageCenterExpirationTag'
import UsageCenterLimitReset from '../parts/UsageCenterLimitReset'
import UsageCenterOverLimit from '../parts/UsageCenterOverLimit'
import UsageCenterPackageButton from '../parts/UsageCenterPackageButton'
import UsageCenterPackageDescription from '../parts/UsageCenterPackageDescription'
import UsageCenterProgress from '../parts/UsageCenterProgress'
import UsageCenterRow from '../parts/UsageCenterRow'
import UsageCenterSection from '../parts/UsageCenterSection'
import UsageCenterTab from '../parts/UsageCenterTab'
import UsageCenterTabPanel from '../parts/UsageCenterTabPanel'

const UsageCenterLivechatTab = () => {
	const shouldShowWarning = useSelector(usageSharedSelectors.getShouldShowUsageCenterWarningDot)

	return <UsageCenterTab showWarningDot={shouldShowWarning.livechat}>{T.translate('general.livechat')}</UsageCenterTab>
}

const LivechatConversationsRow = () => {
	const livechatConversationsUsage = useSelector(usageSelectors.getMonthlyServedLimits)
	const { limit, count, isOverLimit, isOverThreshold75, isOverThreshold90, isOverThreshold95 } =
		livechatConversationsUsage
	const isUnlimited = limit === null
	const shouldShowDesc = !isUnlimited && isNumber(limit) && limit > 0

	const description = shouldShowDesc ? (
		isOverLimit ? (
			<UsageCenterOverLimit type="livechatConversations" limit={limit} />
		) : (
			<UsageCenterLimitReset />
		)
	) : null

	return (
		<UsageCenterRow
			title={T.translate('billing.summary.livechatConversations')}
			icon={mdiChatOutline}
			description={description}
			body={
				<UsageCenterProgress
					value={count}
					limit={limit}
					isUnlimited={isUnlimited}
					isDanger={isOverLimit}
					isWarning={isOverThreshold75 || isOverThreshold90 || isOverThreshold95}
				/>
			}
		/>
	)
}

const AiReplyAssistantRow = () => {
	const packageInfo = useSelector(packageSelectors.getPackageInfo)
	const aiReplyAssistantUsage = useSelector(usageSelectors.getAiReplyAssistLimits)
	const { limit, count, isOverLimit, isOverThreshold75, isOverThreshold90 } = aiReplyAssistantUsage
	const isUnlimited = limit === null
	const shouldShowDesc = !isUnlimited && limit > 0

	const description = shouldShowDesc ? (
		isOverLimit && !packageInfo?.isPro ? (
			<UsageCenterOverLimit type="aiReplyAssistants" limit={limit} />
		) : (
			<UsageCenterLimitReset />
		)
	) : null

	return (
		<UsageCenterRow
			title={T.translate('ai.tools.title')}
			icon={mdiAutoFix}
			description={description}
			body={
				<UsageCenterProgress
					value={count}
					limit={limit}
					isUnlimited={isUnlimited}
					isDanger={isOverLimit && !packageInfo?.isPro}
					isWarning={(isOverThreshold75 || isOverThreshold90) && !packageInfo?.isPro}
				/>
			}
		/>
	)
}

const ShortcutsRow = () => {
	const shortcutsCounts = useSelector(shortcutsSelectors.getActiveShortcutsCount)
	const packageInfo = useSelector(packageSelectors.getPackageInfo)
	const maxShortcutsCount = packageInfo?.userShortcuts
	const isUnlimited = !isNumber(maxShortcutsCount)
	const shouldShowOverLimit = !isUnlimited && shortcutsCounts >= maxShortcutsCount

	return (
		<UsageCenterRow
			title={T.translate('chat.shortcuts.title')}
			icon={mdiLightningBoltOutline}
			description={shouldShowOverLimit && <UsageCenterOverLimit type="shortcuts" limit={maxShortcutsCount} />}
			body={<UsageCenterProgress value={shortcutsCounts} limit={maxShortcutsCount} isUnlimited={isUnlimited} />}
		/>
	)
}

const AgentsRow = () => {
	const { count, limit, isOverLimit } = useSelector(agentsSelectors.getAgentsLimits)
	const isUnlimited = limit === null
	const shouldShowDesc = !isUnlimited && limit > 0 && isOverLimit

	return (
		<UsageCenterRow
			title={T.translate('sideBar.account.agents')}
			icon={mdiAccountMultipleOutline}
			description={shouldShowDesc && <UsageCenterOverLimit type="agents" limit={limit} />}
			body={<UsageCenterProgress value={count} limit={limit} isUnlimited={isUnlimited} isDanger={isOverLimit} />}
		/>
	)
}

const UsageCenterLivechatTabPanel = () => {
	const packageInfo = useSelector(packageSelectors.getPackageInfo)
	const shouldShowLimitSection = !packageInfo?.isExpired

	return (
		<UsageCenterTabPanel>
			<UsageCenterSection heading={T.translate('usageCenter.packageInfo')}>
				<UsageCenterRow
					title={packageInfo?.formattedName}
					titlePostfix={<UsageCenterExpirationTag />}
					description={<UsageCenterPackageDescription />}
					body={<UsageCenterPackageButton />}
				/>
			</UsageCenterSection>
			{shouldShowLimitSection && (
				<UsageCenterSection heading={T.translate('usageCenter.currentUsage')} itemsToShow={4}>
					<LivechatConversationsRow />
					<AiReplyAssistantRow />
					<ShortcutsRow />
					<AgentsRow />
				</UsageCenterSection>
			)}
		</UsageCenterTabPanel>
	)
}

export { UsageCenterLivechatTab, UsageCenterLivechatTabPanel }
