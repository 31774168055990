import { createSelector } from '@reduxjs/toolkit'

import { PackageInterval, PackageName, StripeSubscriptionCalculation, StripeSubscriptionOrderItemCode } from 'models'
import { appSelectors } from 'modules/app'
import { checkoutSelectors } from 'modules/checkout'
import { Discount, DiscountType } from 'modules/checkout/types'
import { packageSelectors } from 'modules/package'
import { stripeSharedSelectors } from 'modules/stripe/sharedSelectors'

import { StripeRootState } from './slice'
import { getPackageFromProducts, getProductExtra, resolveStripeInterval } from './utils'

const getStripeCalculation = (state: StripeRootState) => state.stripe.calculation
const getIsRenewalPending = (state: StripeRootState) => state.stripe.isRenewalPending
const getIsRenewalPendingForPackageUpdate = (state: StripeRootState) => state.stripe.isRenewalPendingForPackageUpdate
const getIsValidatePromoCodePending = (state: StripeRootState) => state.stripe.isValidatePromoCodePending
const getPromoCodeErrorMessage = (state: StripeRootState) => state.stripe.promoCodeErrorMessage
const getStripeBalance = (state: StripeRootState) => state.stripe.balance
const getIsPromoValidated = (state: StripeRootState) => state.stripe.isPromoCodeValidated
const getPromoCodeInterval = (state: StripeRootState) => state.stripe.promoCodeInterval
const getIsCalculatePending = (state: StripeRootState) =>
	state.stripe.calculationPendingCount !== 0 || state.stripe.isDebouncedCalculationPending
const getClientSecret = (state: StripeRootState) => state.stripe.clientSecret
const getInvoice = (state: StripeRootState) => state.stripe.invoice
const getAuthHash = (state: StripeRootState) => state.stripe.authHash
const getCustomerId = (state: StripeRootState) => state.stripe.customerId
const getIsChurnkeyAuthPending = (state: StripeRootState) => state.stripe.isChurnkeyAuthPending

const getCalculationDataByInterval = createSelector(
	[getStripeCalculation, checkoutSelectors.getCheckoutInterval],
	(calculation, interval): StripeSubscriptionCalculation | null => {
		if (!calculation || !interval) return null

		const stripeInterval = resolveStripeInterval(interval)

		return stripeInterval ? calculation[stripeInterval] : null
	},
)

const getPackage = createSelector([getCalculationDataByInterval], (calculation) => {
	return calculation ? getPackageFromProducts(calculation.products) : null
})

const getMonthlyPackage = createSelector([getStripeCalculation], (calculation) => {
	return calculation ? getPackageFromProducts(calculation.month.products) : null
})

const getYearlyPackage = createSelector([getStripeCalculation], (calculation) => {
	return calculation ? getPackageFromProducts(calculation.year.products) : null
})

const getExtraAgents = createSelector([getCalculationDataByInterval], (calculation) => {
	return calculation ? getProductExtra(calculation.products, StripeSubscriptionOrderItemCode.Agent) : null
})

const getExtraChatbots = createSelector([getCalculationDataByInterval], (calculation) => {
	return calculation ? getProductExtra(calculation.products, StripeSubscriptionOrderItemCode.Chatbot) : null
})

const getExtraLivechatConversations = createSelector([getCalculationDataByInterval], (calculation) => {
	return calculation
		? getProductExtra(calculation.products, StripeSubscriptionOrderItemCode.LivechatConversation)
		: null
})

const getExtraAiConversations = createSelector([getCalculationDataByInterval], (calculation) => {
	return calculation ? getProductExtra(calculation.products, StripeSubscriptionOrderItemCode.AiConversation) : null
})

const getTotal = createSelector(
	[getCalculationDataByInterval],
	(calculation): Omit<StripeSubscriptionCalculation, 'products'> | null => {
		if (!calculation) return null

		const { products, ...total } = calculation

		return total
	},
)

const getAgentsTotalPrice = createSelector(
	[getStripeCalculation, checkoutSelectors.getCheckoutInterval],
	(calculation, interval) => {
		if (!calculation || !interval) return 0

		const agents = getProductExtra(calculation.month.products, StripeSubscriptionOrderItemCode.Agent)

		if (!agents) return 0

		return agents.total * interval
	},
)

const getChatbotConversationsTotalPrice = createSelector(
	[getStripeCalculation, checkoutSelectors.getCheckoutInterval],
	(calculation, interval) => {
		if (!calculation || !interval) return 0

		const chatbotConversations = getProductExtra(calculation.month.products, StripeSubscriptionOrderItemCode.Chatbot)

		if (!chatbotConversations) return 0

		return chatbotConversations.total * interval
	},
)

const getLivechatConversationsTotalPrice = createSelector(
	[getStripeCalculation, checkoutSelectors.getCheckoutInterval],
	(calculation, interval) => {
		if (!calculation || !interval) return 0

		const livechatConversations = getProductExtra(
			calculation.month.products,
			StripeSubscriptionOrderItemCode.LivechatConversation,
		)

		if (!livechatConversations) return 0

		return livechatConversations.total * interval
	},
)

const getAiConversationsTotalPrice = createSelector(
	[getStripeCalculation, checkoutSelectors.getCheckoutInterval],
	(calculation, interval) => {
		if (!calculation || !interval) return 0

		const aiConversations = getProductExtra(calculation.month.products, StripeSubscriptionOrderItemCode.AiConversation)

		if (!aiConversations) return 0

		return aiConversations.total * interval
	},
)

const getMonthlyProductPriceTotal = createSelector([getStripeCalculation], (calculation) => {
	if (!calculation) return 0

	const { month } = calculation

	const products = Object.values(StripeSubscriptionOrderItemCode).map(
		(itemCode) => getProductExtra(month.products, itemCode)?.total || 0,
	)

	return products.reduce((a, b) => a + b, 0)
})

const getYearlyProductPriceTotal = createSelector([getStripeCalculation], (calculation) => {
	if (!calculation) return 0

	const { year } = calculation

	const products = Object.values(StripeSubscriptionOrderItemCode).map(
		(itemCode) => getProductExtra(year.products, itemCode)?.total || 0,
	)

	return products.reduce((a, b) => a + b, 0)
})

const getYearlyDiscount = createSelector(
	[getMonthlyProductPriceTotal, getYearlyProductPriceTotal, checkoutSelectors.getCheckoutInterval],
	(monthlyTotal, yearlyTotal, interval) => {
		if (!interval) return 0

		return interval === PackageInterval.Year ? monthlyTotal * interval - yearlyTotal : 0
	},
)

const getStripeCalculationDiscounts = createSelector(
	[
		getCalculationDataByInterval,
		getYearlyDiscount,
		stripeSharedSelectors.getStripePromoCode,
		stripeSharedSelectors.hasStripePromoCode,
	],
	(calculation, yearlyDiscount, promoCode, hasPromoCode): Discount[] => {
		if (!calculation) return []

		const remainingTotal = calculation.prorationTotal ?? 0
		const discounts = [
			{ type: DiscountType.Yearly, value: yearlyDiscount, title: 'billing.yearlyDiscount' },
			{ type: DiscountType.Remaining, value: remainingTotal * -1, title: 'billing.overpaymentDiscount' },
			{
				type: DiscountType.SpecialOffer,
				value: calculation.discountTotal,
				title: 'billing.discount.specialOffer',
				titleParams: {
					promoCode: hasPromoCode ? `(${promoCode[0]})` : '',
				},
			},
		]

		return discounts.filter((discount) => discount.value > 0)
	},
)

const getPriceBeforeDiscount = createSelector(
	[getMonthlyProductPriceTotal, checkoutSelectors.getCheckoutInterval],
	(monthlyTotal, interval) => {
		if (!interval) return 0

		return interval === PackageInterval.Year ? monthlyTotal * interval : monthlyTotal
	},
)

const getStripeUnpaidInvoiceDiscounts = createSelector([getInvoice], (invoice) => {
	if (!invoice) return []

	const remainingTotal = invoice.prorationTotal

	const discounts = [
		{ type: DiscountType.Remaining, value: remainingTotal * -1, title: 'billing.overpaymentDiscount' },
		{
			type: DiscountType.SpecialOffer,
			value: invoice.discountTotal,
			title: 'billing.discount.specialOfferWithoutCode',
		},
	]

	return discounts.filter((discount) => discount.value > 0)
})

const getTaxPercent = createSelector([getCalculationDataByInterval], (calculation) => {
	return calculation ? calculation.taxPercent : null
})

const getPackageBasePrice = createSelector(
	[getMonthlyPackage, checkoutSelectors.getCheckoutInterval],
	(packageCalculation, interval) => {
		if (!packageCalculation || !interval) return null

		return packageCalculation.total * interval
	},
)

const getCalculationExtraProducts = createSelector(
	[getExtraAgents, getExtraChatbots, getExtraLivechatConversations, getExtraAiConversations],
	(extraAgents, extraChatbots, extraLivechatConversations, extraAiConversations) => ({
		extraAgents,
		extraChatbots,
		extraLivechatConversations,
		extraAiConversations,
	}),
)

const getCalculationProductsTotalPrices = createSelector(
	[
		getAgentsTotalPrice,
		getChatbotConversationsTotalPrice,
		getLivechatConversationsTotalPrice,
		getAiConversationsTotalPrice,
	],
	(agentsTotalPrice, chatbotConversationsTotalPrice, livechatConversationsTotalPrice, aiConversationsTotalPrice) => ({
		agentsTotalPrice,
		chatbotConversationsTotalPrice,
		livechatConversationsTotalPrice,
		aiConversationsTotalPrice,
	}),
)

const getCalculationSubscriptionData = createSelector(
	[
		getTotal,
		getPackageBasePrice,
		getCalculationExtraProducts,
		getCalculationProductsTotalPrices,
		getIsCalculatePending,
		getStripeCalculationDiscounts,
		getTaxPercent,
		getPriceBeforeDiscount,
		checkoutSelectors.getCalculationTrigger,
		checkoutSelectors.getIsCheckoutCalculationValid,
		checkoutSelectors.getCheckoutLivechatConversationsBatchSize,
		checkoutSelectors.getCheckoutAiConversationsBatchSize,
	],
	(
		calculationTotal,
		packageBasePrice,
		extraProducts,
		productsTotalPrices,
		isCalculatePending,
		discounts,
		taxPercent,
		priceBeforeDiscount,
		calculationTrigger,
		isCalculationValid,
		livechatConversationsBatchSize,
		aiConversationsBatchSize,
	) => {
		const { extraAgents, extraChatbots, extraLivechatConversations, extraAiConversations } = extraProducts
		const {
			agentsTotalPrice,
			chatbotConversationsTotalPrice,
			livechatConversationsTotalPrice,
			aiConversationsTotalPrice,
		} = productsTotalPrices

		return {
			calculationTotal,
			packageBasePrice,
			extraAgents,
			extraChatbots,
			extraLivechatConversations,
			extraAiConversations,
			isCalculatePending,
			discounts,
			taxPercent,
			priceBeforeDiscount,
			calculationTrigger,
			isCalculationValid,
			livechatConversationsBatchSize,
			aiConversationsBatchSize,
			agentsTotalPrice,
			chatbotConversationsTotalPrice,
			livechatConversationsTotalPrice,
			aiConversationsTotalPrice,
		}
	},
)

const getUnpaidInvoiceSubscriptionData = createSelector(
	[
		getInvoice,
		checkoutSelectors.getCheckoutLivechatConversationsBatchSize,
		checkoutSelectors.getCheckoutAiConversationsBatchSize,
		getStripeUnpaidInvoiceDiscounts,
	],
	(invoice, livechatConversationsBatchSize, aiConversationsBatchSize, discounts) => {
		if (!invoice) return null

		const { products, ...invoiceTotal } = invoice
		const { taxPercent } = invoice
		const extraAgents = getProductExtra(products, StripeSubscriptionOrderItemCode.Agent)
		const extraChatbots = getProductExtra(products, StripeSubscriptionOrderItemCode.Chatbot)
		const extraLivechatConversations = getProductExtra(products, StripeSubscriptionOrderItemCode.LivechatConversation)
		const extraAiConversations = getProductExtra(products, StripeSubscriptionOrderItemCode.AiConversation)

		const packageProduct = getPackageFromProducts(invoice.products)

		return {
			calculationTotal: invoiceTotal,
			packageBasePrice: packageProduct?.total ?? 0,
			extraAgents,
			extraChatbots,
			extraLivechatConversations,
			extraAiConversations,
			isCalculatePending: false,
			discounts,
			calculationTrigger: 0,
			taxPercent,
			isCalculationValid: true,
			livechatConversationsBatchSize,
			aiConversationsBatchSize,
			priceBeforeDiscount: 0,
			agentsTotalPrice: extraAgents?.total ?? 0,
			chatbotConversationsTotalPrice: extraChatbots?.total ?? 0,
			livechatConversationsTotalPrice: extraLivechatConversations?.total ?? 0,
			aiConversationsTotalPrice: extraAiConversations?.total ?? 0,
		}
	},
)

const getShouldFetchStripeSubscription = createSelector([packageSelectors.getPackageInfo], (packageInfo) => {
	if (!packageInfo) return false
	const { name } = packageInfo

	return !(name === PackageName.Trial || name === PackageName.Free)
})

const getShouldShowChurnkey = createSelector(
	[packageSelectors.getIsAllowedStripePaymentGateway, appSelectors.getLocale],
	(isStripeAllowed, locale) => {
		const allowedLocales = ['cs', 'en']

		const isLocaleAllowed = allowedLocales.includes(locale)

		return isStripeAllowed && isLocaleAllowed
	},
)

const getChurnkeyCustomerAttributes = createSelector(
	[packageSelectors.getPackageInfo, appSelectors.getLocale],
	(packageInfo, locale) => {
		if (!packageInfo) return null
		const { name, mrrCZK, isUltimate, interval } = packageInfo

		return {
			name,
			mrrCZK,
			isUltimate,
			interval,
			language: locale || 'en',
		}
	},
)

export const stripeSelectors = {
	getStripeCalculation,
	getIsCalculatePending,
	getCalculationDataByInterval,
	getPackage,
	getMonthlyPackage,
	getYearlyPackage,
	getExtraAgents,
	getExtraChatbots,
	getExtraLivechatConversations,
	getExtraAiConversations,
	getTotal,
	getStripeCalculationDiscounts,
	getPriceBeforeDiscount,
	getTaxPercent,
	getPackageBasePrice,
	getIsRenewalPending,
	getIsRenewalPendingForPackageUpdate,
	getPromoCodeErrorMessage,
	getIsValidatePromoCodePending,
	getStripeBalance,
	getPromoCodeInterval,
	getClientSecret,
	getInvoice,
	getCalculationSubscriptionData,
	getUnpaidInvoiceSubscriptionData,
	getShouldFetchStripeSubscription,
	getIsPromoValidated,
	getAuthHash,
	getCustomerId,
	getIsChurnkeyAuthPending,
	getShouldShowChurnkey,
	getChurnkeyCustomerAttributes,
}
