import { useSelector } from 'react-redux'

import { PackagesType } from 'models'
import { TranslationService as T } from 'shared/services'
import { packageSelectors } from 'modules/package'

import { Text } from 'shared/components/core/Text'

type Props = {
	packageType?: PackagesType
}

const UsageCenterDescriptionText = ({ text }: { text: string }) => (
	<Text as="div" fontSize="sm" color="gray.500">
		{text}
	</Text>
)

const UsageCenterPackageDescription = ({ packageType = PackagesType.Livechat }: Props) => {
	const packageInfo = useSelector(packageSelectors.getPackageInfo)

	if (!packageInfo) return null

	if (packageType === PackagesType.Ai) {
		return <UsageCenterDescriptionText text={T.translate('usageCenter.package.ai.desc')} />
	}

	if (packageInfo.isTrial) {
		return <UsageCenterDescriptionText text={T.translate('usageCenter.package.trial.desc')} />
	}

	if (packageInfo.isPro) {
		return <UsageCenterDescriptionText text={T.translate('usageCenter.package.pro.desc')} />
	}

	return <UsageCenterDescriptionText text={T.translate('usageCenter.package.basic.desc')} />
}

export default UsageCenterPackageDescription
