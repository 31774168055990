import moment from 'moment'

import { MAX_USAGE_THRESHOLD_75, MAX_USAGE_THRESHOLD_90, MAX_USAGE_THRESHOLD_95 } from 'modules/usage/constants'
import {
	FeatureUsageLimitsType,
	pathToUsageCenterTabMap,
	usageCenterTabIndex,
	UsageCenterTabs,
} from 'modules/usage/types'

const NOTIFICATION_MONTH_RANGE = 0.04

export const countNotificationInMonthRatio = (
	usage: number,
	limit: number,
	countDaysInMonth: number,
	dayInMonth: number,
): boolean => {
	const dayInMonthRatio = dayInMonth / countDaysInMonth
	const usageRatio = usage / limit

	return dayInMonthRatio - usageRatio - NOTIFICATION_MONTH_RANGE < 0
}

export const isAllowedNotificationInMonth = (usage: number, limit: number): boolean => {
	const countDaysInMonth = moment().daysInMonth()
	const dayInMonth = moment().date()

	return countNotificationInMonthRatio(usage, limit, countDaysInMonth, dayInMonth)
}

export const resolveTabByLocationPath = (locationPath: string) => {
	let indexToReturn = usageCenterTabIndex[UsageCenterTabs.Livechat]

	pathToUsageCenterTabMap.forEach(({ index, substrings }) => {
		if (substrings.some((string) => locationPath.includes(string))) {
			indexToReturn = index
		}
	})

	return indexToReturn
}

type ThresholdStringType = '75' | '90' | '95'

const MAX_USAGE_THRESHOLDS: Record<ThresholdStringType, number> = {
	'75': MAX_USAGE_THRESHOLD_75,
	'90': MAX_USAGE_THRESHOLD_90,
	'95': MAX_USAGE_THRESHOLD_95,
}

export const calculateUsageLimits = (
	count: number,
	limit: number | null,
	thresholds: ThresholdStringType[] = [],
): FeatureUsageLimitsType => {
	const usage = limit ? count / limit : null

	const overThresholds = thresholds.reduce(
		(acc, threshold, index) => {
			const currentThreshold = MAX_USAGE_THRESHOLDS[threshold]
			const nextThreshold = thresholds[index + 1] ? MAX_USAGE_THRESHOLDS[thresholds[index + 1]] : null

			acc[`isOverThreshold${threshold}`] = usage
				? nextThreshold
					? usage >= currentThreshold && usage < nextThreshold
					: usage >= currentThreshold && count < (limit || Number.POSITIVE_INFINITY)
				: false

			return acc
		},
		{} as Record<`isOverThreshold${ThresholdStringType}`, boolean>,
	)

	const isOverLimit = limit ? count >= limit : false

	return {
		count,
		limit,
		...overThresholds,
		isOverLimit,
	}
}
