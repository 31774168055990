import { batch } from 'react-redux'
import { createSelector, createSlice, original, PayloadAction, unwrapResult } from '@reduxjs/toolkit'

import {
	AllowedActionTypes,
	Chatbot,
	ChatbotAction,
	ChatbotActionAppData,
	ChatbotActionBotTransferData,
	ChatbotActionContactRequireApprovalData,
	ChatbotActionContactRetrieveProperty,
	ChatbotActionData,
	ChatbotActionDataWithMessage,
	ChatbotActionHandoverV2Data,
	ChatbotActionMessage,
	ChatbotActionMessageData,
	ChatbotActionType,
	ChatbotGroupType,
	ChatbotInstalledActionApp,
	ChatbotInteraction,
	ChatbotReply,
	ChatbotTrigger,
	FeatureUsageName,
	SelectedChatbotReply,
} from 'models'
import { AppThunkAction, DashboardState } from 'types'
import { ConfigurationService, TranslationService as T } from 'shared/services'
import { flashMessage, isUndefined } from 'shared/utils'
import { createAiChatbotWorkflowLink, createAutomationEditLink } from 'configuration/routes'
import {
	navigateTo,
	trackAiBuilderOpen,
	trackAiBuilderPublishButtonClick,
	trackAiBuilderUnpublishButtonClick,
	trackChatbotBuilderOpen,
	trackChatbotBuilderPublish,
} from 'utils'
import { fetchAiChatbotsCounts } from 'modules/aiChatbots'
import { autoMessagesSelectors, fetchAutoMessagesCounts } from 'modules/autoMessages'
import { autoMessageStatisticsSelectors } from 'modules/autoMessageStatistics'
import {
	chatbotBuilderSelectors,
	closeInteraction,
	setIsPublishButtonLoading,
	submitInteraction,
} from 'modules/chatbotBuilder'
import { chatbotSelectors, fetchChatbots, fetchChatbotsCounts, getAutomationTranslation } from 'modules/chatbots'
import { chatbotSettingsSelectors } from 'modules/chatbotSettings'
import { chatbotStatisticsSelectors } from 'modules/chatbotStatistics'
import { chatbotTemplatesSelectors, fetchChatbotTemplate } from 'modules/chatbotTemplates'
import {
	chatbotWorkflowSelectors,
	setIsPublishButtonLoading as setIsWorkflowPublishButtonLoading,
	setWorkflowChatbotPublishedVersion,
} from 'modules/chatbotWorkflow'
import { updateDataLayerMiraPublish } from 'modules/dataLayer'
import { logFeatureUsage } from 'modules/features'
import { fetchSources } from 'modules/sources'
import { openSystemModal, SystemModalType } from 'modules/systemModal'

import {
	deleteChatbot,
	fetchChatbot,
	fetchChatbotActions,
	fetchPublishedChatbot,
	postChatbot,
	updateChatbot,
	updateChatbotTitleThunk,
} from './actions'
import {
	ChatbotAdditionalAction,
	ChatbotBuilderInteractionFormData,
	ChatbotBuilderMovableOrCopiableNode,
	ChatbotBuilderNode,
	ChatbotInteractionRequireApprovalData,
	ChatbotPublishSourceType,
	PublishButtonPopoverType,
	SelectedChatbotSection,
} from './types'
import {
	copyChatbotInteraction,
	createInteractionObject,
	deleteChatbotInteractions,
	findAppActionIndex,
	findBotTransferActionIndex,
	findHandoverActionIndex,
	findInteractionActionByType,
	findMessageActionIndex,
	findReplyByNextInteractionId,
	generateChatbotTitle,
	getAddableActionsData,
	getInteractionLevel,
	getIsDefaultProperty,
	getRemovedReplies,
	isActionWithMessageText,
	isAddableChatbotAction,
	isAllowedActionType,
	isInteractionById,
	isInteractionIdGenerated,
	isReplyByNextInteractionId,
	moveChatbotInteraction,
	transformChatbotBuilderNodeToMovableNode,
	transformChatbotBuilderToCopiableNode,
	transformChatbotToBuilderNode,
	transformReply,
} from './utils'

export type ChatbotDetailRootState = Pick<DashboardState, 'chatbotDetail'>
export type ChatbotDetailState = typeof initialState

export const initialState = {
	selectedChatbot: null as null | Chatbot,
	isFetchingChatbot: false,
	currentChatbotForUpdate: null as null | Chatbot,
	isChatbotCreatePending: false,
	isChatbotUpdatePending: false,
	repliesToBeEdited: [] as ChatbotReply[],
	selectedChatbotActions: [] as ChatbotInstalledActionApp[],
	isFetchingChatbotActions: false,
	selectedSection: null as null | SelectedChatbotSection,
	publishButtonPopover: {
		type: PublishButtonPopoverType.Publish,
		show: false,
	},
	isChatbotEdited: false,
}

export const initChatbotDetail =
	(id: string): AppThunkAction =>
	async (dispatch) => {
		const fetchBotAction = await dispatch(fetchChatbot(id))
		const chatbot = unwrapResult(fetchBotAction)
		const { lang } = ConfigurationService.getData()
		if (chatbot.groupType === ChatbotGroupType.Chatbot) {
			dispatch(fetchChatbotsCounts())
			dispatch(fetchChatbots())
			dispatch(fetchChatbotActions(lang))
			dispatch(fetchAiChatbotsCounts())
			trackChatbotBuilderOpen({ type: 'chatbot' })
		} else if (chatbot.groupType === ChatbotGroupType.Message) {
			dispatch(fetchAutoMessagesCounts())
			trackChatbotBuilderOpen({ type: 'autoMessage' })
		} else if (chatbot.groupType === ChatbotGroupType.Ai) {
			chatbot.isActive && dispatch(fetchPublishedChatbot(id))
			dispatch(fetchSources())
			dispatch(fetchAiChatbotsCounts())
			dispatch(fetchChatbotsCounts())
			trackAiBuilderOpen()
		}
	}

export const createChatbotFromTemplate =
	({ id, isOnboardingBot }: { id: string; isOnboardingBot?: boolean }): AppThunkAction =>
	async (dispatch) => {
		try {
			const fetchTemplateResponse = await dispatch(fetchChatbotTemplate(id))
			const unwrappedTemplateResponse = unwrapResult(fetchTemplateResponse)
			const title = isOnboardingBot
				? generateChatbotTitle(T.translate('ai.onboarding.bot.firstTitle'))
				: generateChatbotTitle(unwrappedTemplateResponse.title)
			const postChatbotResponse = await dispatch(
				postChatbot({
					...unwrappedTemplateResponse,
					title,
					type: unwrappedTemplateResponse.botType,
					templateType: unwrappedTemplateResponse.type,
				}),
			)
			const unwrappedChatbotResponse = unwrapResult(postChatbotResponse)
			const { groupType, id: botId } = unwrappedChatbotResponse

			if (groupType === ChatbotGroupType.Ai) {
				navigateTo(createAiChatbotWorkflowLink(botId))
			} else if (groupType) {
				navigateTo(createAutomationEditLink(botId, groupType))
				if (groupType === ChatbotGroupType.Message) {
					dispatch(logFeatureUsage(FeatureUsageName.AutoMessageCreated))
				}
			}
		} catch {
			flashMessage.error('general.error')
		}
	}

export const createAiChatbotFromTemplate =
	({ isOnboardingBot }: { isOnboardingBot?: boolean } = {}): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const aiChatbotTemplate = chatbotTemplatesSelectors.getTemplateAiChatbot(state)
		if (!aiChatbotTemplate) return

		dispatch(createChatbotFromTemplate({ id: aiChatbotTemplate.id, isOnboardingBot }))
	}

const trackChatbotPublishEvent =
	(source: ChatbotPublishSourceType): AppThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const isChatbotActive = chatbotDetailSelectors.getIsSelectedChatbotActive(state)
		const isSelectedAiChatbot = chatbotDetailSelectors.getIsSelectedAiChatbot(state)
		const selectedAiSection = chatbotWorkflowSelectors.getSelectedSection(state)
		const hasUnpublishedChanges = chatbotWorkflowSelectors.getHasUnpublishedChanges(state)

		// Track FAQ bot publish event
		if (!isChatbotActive && !isSelectedAiChatbot) {
			trackChatbotBuilderPublish({ source })
		}

		// Track AI bot publish event
		if (isSelectedAiChatbot) {
			if (!isChatbotActive || (isChatbotActive && hasUnpublishedChanges)) {
				dispatch(updateDataLayerMiraPublish())
				trackAiBuilderPublishButtonClick({ section: selectedAiSection, source })
			} else {
				trackAiBuilderUnpublishButtonClick({ section: selectedAiSection, source })
			}
		}
	}

export const publishChatbot =
	(data: { source: ChatbotPublishSourceType }): AppThunkAction =>
	async (dispatch, getState) => {
		const { source } = data
		const state = getState()
		const selectedChatbot = chatbotDetailSelectors.getSelectedChatbot(state)
		const isAutoMessage = chatbotDetailSelectors.getIsSelectedAutoMessage(state)
		if (!selectedChatbot) return

		// Track publish event
		dispatch(trackChatbotPublishEvent(source))

		const { id } = selectedChatbot
		const changes = { isActive: true }
		dispatch(setIsPublishButtonLoading(true))
		dispatch(setIsWorkflowPublishButtonLoading(true))
		const actionResult = await dispatch(updateChatbot({ botId: id, changes }))

		if (updateChatbot.fulfilled.match(actionResult)) {
			flashMessage.success(isAutoMessage ? 'chatbot.update.success.autoMessage' : 'chatbot.update.success', {
				testId: 'flashMessage-success-chatbot-publish',
			})
		} else flashMessage.error('general.error', { testId: 'flashMessage-error-chatbot-publish' })

		dispatch(setIsPublishButtonLoading(false))
		dispatch(setIsWorkflowPublishButtonLoading(false))
	}

export const changeAiBotState =
	(data: { source: ChatbotPublishSourceType }): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const selectedChatbot = chatbotDetailSelectors.getSelectedChatbot(state)
		const isSelectedChatbotActive = chatbotDetailSelectors.getIsSelectedChatbotActive(state)
		const hasUnpublishedChanges = chatbotWorkflowSelectors.getHasUnpublishedChanges(state)
		if (!selectedChatbot) return

		// Track publish event
		dispatch(trackChatbotPublishEvent(data.source))

		const { id } = selectedChatbot
		const isActive = !isSelectedChatbotActive || hasUnpublishedChanges
		const changes = { isActive }
		const actionResult = await dispatch(updateChatbot({ botId: id, changes }))
		const chatbot = unwrapResult(actionResult)
		if (isActive) dispatch(setWorkflowChatbotPublishedVersion(chatbot))
	}

export const changeChatbotState =
	(data: { source: ChatbotPublishSourceType }): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const selectedChatbot = chatbotDetailSelectors.getSelectedChatbot(state)
		const isSelectedChatbotActive = chatbotDetailSelectors.getIsSelectedChatbotActive(state)
		if (!selectedChatbot) return

		// Track publish event
		dispatch(trackChatbotPublishEvent(data.source))

		const { id } = selectedChatbot
		const changes = { isActive: !isSelectedChatbotActive }
		const actionResult = await dispatch(updateChatbot({ botId: id, changes }))
		unwrapResult(actionResult)
	}

export const updateChatbotTitle =
	(title: string): AppThunkAction =>
	async (dispatch, getState) => {
		const selectedChatbot = chatbotDetailSelectors.getSelectedChatbot(getState())
		const isSelectedChatbotActive = chatbotDetailSelectors.getIsSelectedChatbotActive(getState())
		const isAutoMessage = chatbotDetailSelectors.getIsSelectedAutoMessage(getState())

		if (!selectedChatbot) return
		dispatch(actions.updateChatbotTitle(title))

		const { id } = selectedChatbot

		const resultAction = await dispatch(
			updateChatbotTitleThunk({ botId: id, title, ...(isSelectedChatbotActive && { isActive: false }) }),
		)
		if (updateChatbotTitleThunk.fulfilled.match(resultAction)) {
			if (isSelectedChatbotActive) {
				flashMessage.success(getAutomationTranslation('chatbot.formEditSaved.success', isAutoMessage), {
					testId: 'flashMessage-success-chatbot-title-saved',
				})
				dispatch(setPublishButtonPopover({ type: PublishButtonPopoverType.SavedAsDraft, show: true }))
			}
		} else {
			flashMessage.error('general.error')
		}
	}

export const updateAiChatbotTitle =
	(title: string): AppThunkAction =>
	async (dispatch, getState) => {
		const selectedChatbot = chatbotDetailSelectors.getSelectedChatbot(getState())

		if (!selectedChatbot) return
		dispatch(actions.updateChatbotTitle(title))

		const { id } = selectedChatbot

		const resultAction = await dispatch(updateChatbotTitleThunk({ botId: id, title }))
		if (updateChatbotTitleThunk.rejected.match(resultAction)) {
			flashMessage.error('general.error')
		}
	}

export const moveChatbotNode =
	(interactionId: string, newParentInteractionId: string): AppThunkAction =>
	(dispatch) => {
		dispatch(actions.moveInteraction({ interactionId, newParentInteractionId }))
		dispatch(updateSelectedChatbot())
	}

export const copyChatbotNode =
	(interactionId: string, newParentInteractionId: string): AppThunkAction =>
	(dispatch) => {
		dispatch(actions.copyInteraction({ interactionId, newParentInteractionId }))
		dispatch(updateSelectedChatbot())
	}

export const updateSelectedChatbot = (): AppThunkAction => async (dispatch, getState) => {
	const selectedChatbot = chatbotDetailSelectors.getSelectedChatbot(getState())
	const trigger = chatbotSettingsSelectors.getTrigger(getState())
	const isSelectedChatbotActive = chatbotDetailSelectors.getIsSelectedChatbotActive(getState())
	const isAutoMessage = chatbotDetailSelectors.getIsSelectedAutoMessage(getState())

	if (!selectedChatbot) return

	const { id, ...chatbotData } = selectedChatbot
	// Deactivate chatbot when it's changed
	const changes = { ...chatbotData, isActive: false, ...(trigger && { trigger }) }

	const resultAction = await dispatch(updateChatbot({ botId: id, changes }))
	if (updateChatbot.fulfilled.match(resultAction)) {
		flashMessage.success(getAutomationTranslation('chatbot.formEditSaved.success', isAutoMessage), {
			testId: 'flashMessage-success-chatbot-edit-saved',
		})
		if (isSelectedChatbotActive) {
			dispatch(setPublishButtonPopover({ type: PublishButtonPopoverType.SavedAsDraft, show: true }))
		}
	} else {
		flashMessage.error('general.error')
	}
}

export const deleteChatbotInteractionAction =
	(interactionId: string): AppThunkAction =>
	(dispatch) => {
		dispatch(
			openSystemModal({
				name: SystemModalType.Delete,
				data: {
					title: 'chatbotBuilder.replies.deleteModal.title',
					text: 'chatbotBuilder.replies.deleteModal.body',
					onConfirm: () => {
						batch(() => {
							dispatch(deleteNodesByInteractionId({ interactionId }))
							dispatch(updateSelectedChatbot())
							dispatch(closeInteraction())
						})
					},
				},
			}),
		)
	}

export const submitChatbotBuilderInteractionForm =
	({
		interactionId,
		replies,
		message,
		group,
		title,
		actions,
		retrievePropertyActions,
		requireApprovalAction,
		botTransfer,
		online,
		offline,
	}: ChatbotBuilderInteractionFormData): AppThunkAction =>
	(dispatch) => {
		batch(() => {
			title && dispatch(updateReplyTextByInteractionId({ interactionId, text: title }))
			dispatch(updateRepliesByInteractionId({ interactionId, replies: replies ?? [] })) // NOTE: replies can be undefined if there are no replies,
			message && dispatch(updateMessageByInteractionId({ interactionId, message }))
			online && offline && dispatch(updateHandoverByInteractionId({ interactionId, online, offline }))
			dispatch(updateGroupByInteractionId({ interactionId, group }))
			botTransfer && dispatch(updateBotTransferByInteractionId({ interactionId, botTransfer }))
			dispatch(updateRetrievePropertyActionsByInteractionId({ interactionId, retrievePropertyActions }))
			dispatch(updateAddableActionsByInteractionId({ interactionId, actions }))
			dispatch(updateRequireApprovalByInteractionId({ interactionId, requireApprovalAction }))
			dispatch(updateSelectedChatbot())
			dispatch(submitInteraction())
		})
	}

const chatbotDetailSlice = createSlice({
	name: 'chatbotDetail',
	initialState,
	reducers: {
		setSelectedChatbot: (state, { payload }: PayloadAction<Chatbot | null>) => {
			state.selectedChatbot = payload
		},
		setSelectedSection: (state, { payload }: PayloadAction<SelectedChatbotSection>) => {
			state.selectedSection = payload
		},
		setPublishButtonPopover: (state, { payload }: PayloadAction<{ type: PublishButtonPopoverType; show: boolean }>) => {
			state.publishButtonPopover = payload
		},
		updateIsAllowedWhenChatIsServed: (state, { payload }: PayloadAction<boolean | undefined>) => {
			const { selectedChatbot } = state
			if (!selectedChatbot || isUndefined(payload)) return
			selectedChatbot.isAllowedWhenChatIsServed = payload
		},
		setIsChatbotActive: (state, { payload }: PayloadAction<boolean>) => {
			const { selectedChatbot } = state
			if (!selectedChatbot) return
			selectedChatbot.isActive = payload
		},
		setRepliesToBeEdited: (state, { payload }: PayloadAction<ChatbotReply[]>) => {
			state.repliesToBeEdited = payload
		},
		updateMessageByInteractionId: (state, { payload }: PayloadAction<{ interactionId: string; message: string }>) => {
			const { message, interactionId } = payload
			const { selectedChatbot } = state
			if (!selectedChatbot) return

			const interactionIndex = selectedChatbot.interactions.findIndex(isInteractionById(interactionId))
			if (interactionIndex < 0) return

			const indexOfMessage = selectedChatbot.interactions[interactionIndex].actions.findIndex((a) =>
				isActionWithMessageText(a.type),
			)

			const selectedActionData = selectedChatbot.interactions[interactionIndex].actions[indexOfMessage]
				.data as ChatbotActionDataWithMessage

			selectedActionData.text = message
		},
		updateHandoverByInteractionId: (
			state,
			{
				payload,
			}: PayloadAction<{
				interactionId: string
				online: ChatbotActionHandoverV2Data['online']
				offline: ChatbotActionHandoverV2Data['offline']
			}>,
		) => {
			const { online, offline, interactionId } = payload
			const { selectedChatbot } = state
			if (!selectedChatbot) return

			const interactionIndex = selectedChatbot.interactions.findIndex(isInteractionById(interactionId))
			if (interactionIndex < 0) return

			const interactionHandoverIndex = selectedChatbot.interactions[interactionIndex].actions.findIndex(
				(a) => a.type === ChatbotActionType.HandoverV2,
			)

			const selectedActionData = selectedChatbot.interactions[interactionIndex].actions[interactionHandoverIndex]
				.data as ChatbotActionHandoverV2Data

			selectedActionData.online = online
			selectedActionData.offline = offline
		},
		updateGroupByInteractionId: (state, { payload }: PayloadAction<{ interactionId: string; group?: string }>) => {
			const { group, interactionId } = payload
			const { selectedChatbot } = state
			if (!selectedChatbot) return

			const interactionIndex = selectedChatbot.interactions.findIndex(isInteractionById(interactionId))
			if (interactionIndex < 0) return

			const actionIndex = findHandoverActionIndex(selectedChatbot.interactions[interactionIndex].actions)

			const selectedAction = selectedChatbot.interactions[interactionIndex].actions[actionIndex]

			if (selectedAction && selectedAction.type === ChatbotActionType.HandoverV2) {
				selectedAction.data.online.groupId = group
				selectedAction.data.offline.groupId = group
			}
		},
		updateBotTransferByInteractionId: (
			state,
			{ payload }: PayloadAction<{ interactionId: string; botTransfer: string }>,
		) => {
			const { botTransfer, interactionId } = payload
			const { selectedChatbot } = state
			if (!selectedChatbot) return

			const interactionIndex = selectedChatbot.interactions.findIndex(isInteractionById(interactionId))
			if (interactionIndex < 0) return

			const actionIndex = findBotTransferActionIndex(selectedChatbot.interactions[interactionIndex].actions)

			const selectedAction = selectedChatbot.interactions[interactionIndex].actions[actionIndex]

			if (selectedAction && selectedAction.type === ChatbotActionType.BotTransfer) {
				selectedAction.data.botId = botTransfer
			}
		},
		updateAddableActionsByInteractionId: (
			state,
			{ payload }: PayloadAction<{ interactionId: string; actions: ChatbotAdditionalAction[] }>,
		) => {
			const { interactionId, actions } = payload
			const { selectedChatbot } = state
			if (!selectedChatbot) return

			const interactionIndex = selectedChatbot.interactions.findIndex(isInteractionById(interactionId))
			if (interactionIndex < 0) return

			// Remove all tagging actions from the interaction
			selectedChatbot.interactions[interactionIndex].actions = selectedChatbot.interactions[
				interactionIndex
			].actions.filter((a) => !isAddableChatbotAction(a.type))

			if (!actions) return
			// Add new tagging action if there is at least one tag selected
			actions.forEach((action) => {
				if (isAddableChatbotAction(action.name) && action.data.length > 0) {
					selectedChatbot.interactions[interactionIndex].actions.push({
						type: action.name,
						data: {
							tagIds: action.data,
						},
					})
				}
			})
		},
		updateRequireApprovalByInteractionId: (
			state,
			{
				payload,
			}: PayloadAction<{ interactionId: string; requireApprovalAction: ChatbotInteractionRequireApprovalData }>,
		) => {
			const { interactionId, requireApprovalAction } = payload
			const { selectedChatbot } = state
			if (!selectedChatbot) return

			const interactionIndex = selectedChatbot.interactions.findIndex(isInteractionById(interactionId))
			if (interactionIndex < 0) return

			selectedChatbot.interactions[interactionIndex].actions = selectedChatbot.interactions[
				interactionIndex
			].actions.filter((a) => a.type !== ChatbotActionType.ContactRequireApproval)

			if (requireApprovalAction.isEnabled) {
				const url = requireApprovalAction.url || null
				const text = requireApprovalAction.text || null

				selectedChatbot.interactions[interactionIndex].actions.unshift({
					type: ChatbotActionType.ContactRequireApproval,
					data: {
						url,
						text,
					},
				})
			}
		},
		updateRetrievePropertyActionsByInteractionId: (
			state,
			{
				payload,
			}: PayloadAction<{ interactionId: string; retrievePropertyActions: ChatbotActionContactRetrieveProperty[] }>,
		) => {
			const { interactionId, retrievePropertyActions } = payload
			const { selectedChatbot } = state
			if (!selectedChatbot) return

			const interactionIndex = selectedChatbot.interactions.findIndex(isInteractionById(interactionId))
			if (interactionIndex < 0) return

			// Remove all retrieve property actions from the interaction
			selectedChatbot.interactions[interactionIndex].actions = selectedChatbot.interactions[
				interactionIndex
			].actions.filter((a) => a.type !== ChatbotActionType.ContactRetrieveProperty)

			// Add actions from form if there is at least one field selected
			if (retrievePropertyActions.length > 0) {
				selectedChatbot.interactions[interactionIndex].actions.unshift(...retrievePropertyActions)
			}
		},
		updateAppDataByInteractionId: (
			state,
			{ payload }: PayloadAction<{ interactionId: string; options: ChatbotActionAppData['options'] }>,
		) => {
			const { interactionId, options } = payload
			const { selectedChatbot } = state
			if (!selectedChatbot) return

			const interactionIndex = selectedChatbot.interactions.findIndex(isInteractionById(interactionId))
			if (interactionIndex < 0) return

			const actionIndex = findAppActionIndex(selectedChatbot.interactions[interactionIndex].actions)

			const selectedAction = selectedChatbot.interactions[interactionIndex].actions[actionIndex]

			if (selectedAction && selectedAction.type === ChatbotActionType.App) {
				const { appId, actionId } = selectedAction.data
				selectedAction.data = { appId, actionId, ...(options && { options }) }
			}
		},
		updateRepliesByInteractionId: (
			state,
			{ payload }: PayloadAction<{ interactionId: string; replies: SelectedChatbotReply[] }>,
		) => {
			const { replies, interactionId } = payload
			const { selectedChatbot } = state
			if (!selectedChatbot) return

			const interactionIndex = selectedChatbot.interactions.findIndex(isInteractionById(interactionId))
			if (interactionIndex < 0) return

			// Replies can only be added for message action
			const messageActionIndex = findMessageActionIndex(selectedChatbot.interactions[interactionIndex].actions)

			// Delete interactions for removed replies
			const selectedAction = selectedChatbot.interactions[interactionIndex].actions[messageActionIndex]

			if (selectedAction && selectedAction.type === ChatbotActionType.Message) {
				const { data: interactionData } = selectedAction

				const originalReplies = original(interactionData.replies)
				if (originalReplies) {
					const removedReplies = getRemovedReplies(originalReplies, replies)
					removedReplies.forEach((reply) => {
						selectedChatbot.interactions = deleteChatbotInteractions(selectedChatbot, reply.nextInteractionId)
					})
				}

				// Update interaction replies
				if (interactionData) {
					const formattedReplies = replies.map((reply) => ({
						nextInteractionId: reply.nextInteractionId,
						text: reply.text,
						type: reply.type,
					}))
					interactionData.replies = formattedReplies
				}
			}

			// Add interactions for new replies
			replies.forEach((reply) => {
				// Skip already saved replies
				if (!isInteractionIdGenerated(reply.nextInteractionId ?? '')) return

				// Don't push interaction if it already exists
				const interactionExists = selectedChatbot.interactions.find(isInteractionById(reply.nextInteractionId))
				if (interactionExists) return

				selectedChatbot.interactions.push(
					createInteractionObject({
						id: reply.nextInteractionId,
						type: reply.nodeType,
						appId: reply.appId,
						actionId: reply.actionId,
						actions: reply.actions,
					}),
				)
			})
		},
		deleteNodesByInteractionId: (state, { payload }: PayloadAction<{ interactionId: string }>) => {
			const { interactionId } = payload
			const { selectedChatbot } = state
			if (!selectedChatbot) return
			selectedChatbot.interactions = deleteChatbotInteractions(selectedChatbot, interactionId)
		},
		updateReplyTextByInteractionId: (state, { payload }: PayloadAction<{ interactionId: string; text: string }>) => {
			const { interactionId, text } = payload
			const { selectedChatbot } = state
			if (!selectedChatbot) return

			for (const interaction of selectedChatbot.interactions) {
				const messageActionIndex = findMessageActionIndex(interaction.actions)

				const messageAction = interaction.actions[messageActionIndex]

				if (messageAction && messageAction.type === ChatbotActionType.Message) {
					const reply = messageAction.data.replies.find(isReplyByNextInteractionId(interactionId))
					if (reply) {
						reply.text = text
						return
					}
				}
			}
		},
		updateChatbotTitle: (state, { payload }: PayloadAction<string>) => {
			const { selectedChatbot } = state
			if (!selectedChatbot) return
			selectedChatbot.title = payload
		},
		moveInteraction: (state, { payload }: PayloadAction<{ interactionId: string; newParentInteractionId: string }>) => {
			const { interactionId, newParentInteractionId } = payload
			const { selectedChatbot } = state
			if (!selectedChatbot) return
			selectedChatbot.interactions = moveChatbotInteraction(selectedChatbot, interactionId, newParentInteractionId)
		},
		copyInteraction: (state, { payload }: PayloadAction<{ interactionId: string; newParentInteractionId: string }>) => {
			const { interactionId, newParentInteractionId } = payload
			const { selectedChatbot } = state
			if (!selectedChatbot) return
			selectedChatbot.interactions = copyChatbotInteraction(selectedChatbot, interactionId, newParentInteractionId)
		},
		setSelectedChatbotIdentityId: (state, { payload }: PayloadAction<string>) => {
			const { selectedChatbot } = state
			if (!selectedChatbot) return
			selectedChatbot.identityId = payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(postChatbot.pending, (state) => {
			state.isChatbotCreatePending = true
		})
		builder
			.addCase(postChatbot.fulfilled, (state, { payload }) => {
				state.isChatbotCreatePending = false
				state.selectedChatbot = payload
			})
			.addCase(postChatbot.rejected, (state) => {
				state.isChatbotCreatePending = false
			})

		builder
			.addCase(fetchChatbot.pending, (state) => {
				state.isFetchingChatbot = true
			})
			.addCase(fetchChatbot.fulfilled, (state, { payload }) => {
				state.isFetchingChatbot = false
				state.isChatbotEdited = false
				state.selectedChatbot = payload
			})
			.addCase(fetchChatbot.rejected, (state) => {
				state.isFetchingChatbot = false
			})

		builder
			.addCase(updateChatbot.pending, (state) => {
				state.isChatbotUpdatePending = true
			})
			.addCase(updateChatbot.fulfilled, (state, { payload }) => {
				state.isChatbotUpdatePending = false
				state.isChatbotEdited = true
				state.selectedChatbot = payload
			})
			.addCase(updateChatbot.rejected, (state) => {
				state.isChatbotUpdatePending = false
			})

		builder
			.addCase(updateChatbotTitleThunk.pending, (state) => {
				state.isChatbotUpdatePending = true
			})
			.addCase(updateChatbotTitleThunk.fulfilled, (state, { payload }) => {
				state.isChatbotUpdatePending = false
				state.isChatbotEdited = true
				if (state.selectedChatbot) {
					state.selectedChatbot.title = payload.title
					state.selectedChatbot.isActive = payload.isActive
				}
			})
			.addCase(updateChatbotTitleThunk.rejected, (state) => {
				state.isChatbotUpdatePending = false
			})

		builder
			.addCase(fetchChatbotActions.pending, (state) => {
				state.isFetchingChatbotActions = true
				state.selectedChatbotActions = []
			})
			.addCase(fetchChatbotActions.fulfilled, (state, { payload }) => {
				state.isFetchingChatbotActions = false
				state.selectedChatbotActions = payload
			})
			.addCase(fetchChatbotActions.rejected, (state) => {
				state.isFetchingChatbotActions = false
				state.selectedChatbotActions = []
			})

		builder.addCase(deleteChatbot.fulfilled, (state) => {
			state.selectedChatbot = null
		})
	},
})

const getIsChatbotCreatePending = (state: ChatbotDetailRootState) => state.chatbotDetail.isChatbotCreatePending
const getIsChatbotUpdatePending = (state: ChatbotDetailRootState) => state.chatbotDetail.isChatbotUpdatePending
const getIsFetchingChatbot = (state: ChatbotDetailRootState) => state.chatbotDetail.isFetchingChatbot
const getSelectedChatbot = (state: ChatbotDetailRootState) => state.chatbotDetail.selectedChatbot
const getSelectedSection = (state: ChatbotDetailRootState) => state.chatbotDetail.selectedSection
const getUpdatedChatbot = (state: ChatbotDetailRootState) => state.chatbotDetail.currentChatbotForUpdate
const getRepliesToBeEdited = (state: ChatbotDetailRootState) => state.chatbotDetail.repliesToBeEdited
const getChatbotInteractions = (state: ChatbotDetailRootState) => state.chatbotDetail.selectedChatbot?.interactions
const getChatbotActions = (state: ChatbotDetailRootState) => state.chatbotDetail.selectedChatbotActions
const getIsFetchingChatbotActions = (state: ChatbotDetailRootState) => state.chatbotDetail.isFetchingChatbotActions
const getPublishButtonPopoverType = (state: ChatbotDetailRootState) => state.chatbotDetail.publishButtonPopover.type
const getShowPublishButtonPopover = (state: ChatbotDetailRootState) => state.chatbotDetail.publishButtonPopover.show
const getIsChatbotEdited = (state: ChatbotDetailRootState) => state.chatbotDetail.isChatbotEdited

const getSelectedInteractionById = createSelector(
	[getSelectedChatbot, chatbotBuilderSelectors.getSelectedInteractionId],
	(chatbot, interactionId): ChatbotInteraction | null =>
		chatbot?.interactions.find((interaction) => interaction.id === interactionId) ?? null,
)

const getInteractionsReplies = createSelector([getSelectedInteractionById], (interaction): SelectedChatbotReply[] => {
	// Only message action data contains replies
	const messageAction = findInteractionActionByType(interaction, ChatbotActionType.Message)
	if (!messageAction) return []

	const messageActionData = messageAction.data as ChatbotActionMessageData

	const { replies } = messageActionData

	return replies.map(transformReply)
})

const getInteractionsGroup = createSelector([getSelectedInteractionById], (interaction): string | null => {
	// Only handover action data contains group
	const handoverAction = findInteractionActionByType(interaction, ChatbotActionType.HandoverV2)
	if (!handoverAction) return null

	const handoverActionData = handoverAction.data as ChatbotActionHandoverV2Data

	return handoverActionData.online.groupId || null
})

const getInteractionsBotTransferId = createSelector(
	[getSelectedInteractionById],
	(interaction): ChatbotActionBotTransferData['botId'] | null => {
		// Only botTransfer action data contains botId
		const botTransferAction = findInteractionActionByType(interaction, ChatbotActionType.BotTransfer)
		if (!botTransferAction) return null

		const botTransferActionData = botTransferAction.data as ChatbotActionBotTransferData

		return botTransferActionData.botId
	},
)

const makeGetInteractionGroupById = (id: string | null) => {
	return createSelector([getSelectedChatbot], (chatbot): string | null => {
		const interaction = chatbot?.interactions.find((i) => i.id === id)
		if (!interaction) return null
		const handoverAction = findInteractionActionByType(interaction, ChatbotActionType.HandoverV2)
		if (!handoverAction) return null
		const handoverActionData = handoverAction.data as ChatbotActionHandoverV2Data

		return handoverActionData.online.groupId || null
	})
}

const makeGetInteractionsBotTransferDataById = (id: string | null) => {
	return createSelector(
		[getSelectedChatbot, chatbotSelectors.getChatbots],
		(chatbot, bots): { botTitle: string | null; isInvalid: boolean } | null => {
			const interaction = chatbot?.interactions.find((i) => i.id === id)
			if (!interaction) return null
			const botTransferAction = findInteractionActionByType(interaction, ChatbotActionType.BotTransfer)
			if (!botTransferAction) return null
			const botTransferActionData = botTransferAction.data as ChatbotActionBotTransferData

			return {
				botTitle: bots.find((b) => b.id === botTransferActionData.botId)?.title ?? null,
				isInvalid: botTransferActionData.botId.length > 0 && !bots.some((b) => b.id === botTransferActionData.botId),
			}
		},
	)
}

const getInteractionsAddableActions = createSelector(
	[getSelectedInteractionById],
	(interaction): ChatbotAdditionalAction[] => {
		return getAddableActionsData(interaction)
	},
)

const getInteractionRetrievePropertyActions = createSelector(
	[getSelectedInteractionById],
	(interaction): ChatbotActionContactRetrieveProperty[] => {
		const retrievePropertiesActions = interaction?.actions.filter(
			(action) => action.type === ChatbotActionType.ContactRetrieveProperty,
		)
		if (!retrievePropertiesActions) return []
		return retrievePropertiesActions
	},
)

const getInteractionContactRequireApprovalActionData = createSelector(
	[getSelectedInteractionById],
	(interaction): ChatbotInteractionRequireApprovalData => {
		const data: ChatbotInteractionRequireApprovalData = {
			isEnabled: false,
			url: '',
			text: T.translate('chatbox.texts.offline.privacyNoticeMessage.placeholder'),
		}

		const requireApprovalAction = findInteractionActionByType(interaction, ChatbotActionType.ContactRequireApproval)
		if (!requireApprovalAction) return data

		const requireApprovalActionData = requireApprovalAction.data as ChatbotActionContactRequireApprovalData
		return { isEnabled: true, url: requireApprovalActionData.url, text: requireApprovalActionData.text ?? '' }
	},
)

const getInteractionsAppId = createSelector([getSelectedInteractionById], (interaction): string | null => {
	const appAction = findInteractionActionByType(interaction, ChatbotActionType.App)
	if (!appAction) return null

	const appActionData = appAction.data as ChatbotActionAppData
	return appActionData.appId
})

const getInteractionsAppData = createSelector(
	[getSelectedInteractionById],
	(interaction): ChatbotActionAppData | null => {
		const appAction = findInteractionActionByType(interaction, ChatbotActionType.App)
		if (!appAction) return null

		return appAction.data as ChatbotActionAppData
	},
)

const getInteractionsActionId = createSelector([getSelectedInteractionById], (interaction): string | null => {
	const appAction = findInteractionActionByType(interaction, ChatbotActionType.App)
	if (!appAction) return null

	const appActionData = appAction.data as ChatbotActionAppData
	return appActionData.actionId ?? null
})

const makeGetActionAppDataById = (botId: string) => {
	return createSelector([getSelectedChatbot], (chatbot): ChatbotActionAppData | undefined => {
		if (!chatbot || !chatbot.interactions) return undefined
		const interaction = chatbot.interactions.find((i) => i.id === botId)
		if (!interaction) return undefined

		const appAction = findInteractionActionByType(interaction, ChatbotActionType.App)
		if (!appAction) return undefined

		const { data } = appAction

		if (data && 'appId' in data && 'actionId' in data) {
			return data
		}
		return undefined
	})
}

const makeGetActionByIds = (appId?: string, actionId?: string) => {
	return createSelector([getChatbotActions], (actions): ChatbotInstalledActionApp | undefined => {
		if (!actions || !appId || !actionId) return undefined
		const action = actions.find((a) => a.data.appId === appId && a.data.actionId === actionId)

		return action
	})
}

const makeGetHasInteractionActionsById = (interactionId: string) => {
	return createSelector([getSelectedChatbot], (chatbot): boolean | undefined => {
		if (!chatbot || !chatbot.interactions) return undefined
		const interaction = chatbot.interactions.find((i) => i.id === interactionId)
		if (!interaction) return undefined

		// These are actions that can be added by user in the actions tab of the interaction drawer
		const tagsAction = findInteractionActionByType(interaction, [
			ChatbotActionType.ChatAddTags,
			ChatbotActionType.ContactAddTags,
		])

		return !!tagsAction
	})
}

const getSelectedAction = createSelector(
	[getInteractionsAppId, getInteractionsActionId, getChatbotActions],
	(appId, actionId, actions): ChatbotInstalledActionApp | undefined => {
		const selectedAction = actions.find((action) => action.data.appId === appId && action.data.actionId === actionId)
		return selectedAction
	},
)

const getCurrentInteractionsMessage = createSelector(
	[getSelectedInteractionById],
	(interaction): ChatbotActionMessageData['text'] | null => {
		const actionWithMessageText = findInteractionActionByType(interaction, [ChatbotActionType.Message])
		if (!actionWithMessageText) return null

		const messageData = actionWithMessageText.data as ChatbotActionMessageData

		return messageData.text
	},
)

const getCurrentHandover = createSelector(
	[getSelectedInteractionById],
	(interaction): ChatbotActionHandoverV2Data | null => {
		const handoverAction = findInteractionActionByType(interaction, [ChatbotActionType.HandoverV2])
		if (!handoverAction) return null

		const messageData = handoverAction.data as ChatbotActionHandoverV2Data

		return messageData
	},
)

const getInteractionsIndex = createSelector(
	[getSelectedChatbot, chatbotBuilderSelectors.getSelectedInteractionId],
	(chatbot, interactionId): number | null => {
		if (!chatbot) return null
		const selectedInteraction = chatbot.interactions.find((interaction) => interaction.id === interactionId)
		if (selectedInteraction) {
			return chatbot.interactions.indexOf(selectedInteraction)
		}
		return null
	},
)

const getDataForFirstActionOfFirstInteraction = createSelector(
	[getSelectedChatbot],
	(chatbot): ChatbotActionData | null => {
		return chatbot?.interactions[0].actions[0].data ?? null
	},
)

const getTypeOfFirstActionOfSelectedInteraction = createSelector(
	[getSelectedChatbot, getInteractionsIndex],
	(chatbot, index): AllowedActionTypes | null => {
		if (index === null) return null

		// ChatAddTags and ContactAddTags actions are not displayed in the builder as a separate chatbot node (see type AllowedActionTypes)
		const filteredActions = chatbot?.interactions[index].actions.filter((a) => isAllowedActionType(a.type))

		if (!filteredActions) return null

		return filteredActions[0].type as AllowedActionTypes
	},
)

const getChatbotBuilderNode = createSelector([getSelectedChatbot], (chatbot): ChatbotBuilderNode | null => {
	if (!chatbot) return null
	return transformChatbotToBuilderNode(chatbot)
})

const getChatbotTitle = createSelector([getSelectedChatbot], (chatbot): string => chatbot?.title ?? '')

const getIsSelectedChatbotActive = createSelector(
	[getSelectedChatbot],
	(chatbot): boolean => chatbot?.isActive ?? false,
)

const getSelectedChatbotTrigger = createSelector([getSelectedChatbot], (chatbot): ChatbotTrigger | null => {
	return chatbot?.trigger ?? null
})

const getChatbotId = createSelector([getSelectedChatbot], (chatbot): string => chatbot?.id ?? '')

const getReplyTextByNextInteractionId = createSelector(
	[getSelectedChatbot, chatbotBuilderSelectors.getSelectedInteractionId],
	(chatbot, interactionId): string | undefined => {
		if (!chatbot || !interactionId) return undefined
		let selectedReply: ChatbotReply | null = null

		const { interactions } = chatbot
		for (const interaction of interactions) {
			const messageAction = findInteractionActionByType(interaction, ChatbotActionType.Message)

			if (messageAction && messageAction.type === ChatbotActionType.Message) {
				const replyMatchingInteractionsId = findReplyByNextInteractionId(messageAction.data.replies, interactionId)
				if (replyMatchingInteractionsId) {
					selectedReply = replyMatchingInteractionsId
				}
			}
		}

		return selectedReply ? selectedReply.text : undefined
	},
)

const makeGetInteractionTypeById = (interactionsId: string) => {
	return createSelector([getSelectedChatbot], (chatbot): AllowedActionTypes | undefined => {
		if (!chatbot || !chatbot.interactions) return undefined
		const interaction = chatbot.interactions.find((i) => i.id === interactionsId)
		if (!interaction) return undefined
		const filteredAction = interaction.actions.find((a) => isAllowedActionType(a.type))

		if (!filteredAction) return undefined
		return filteredAction.type as AllowedActionTypes
	})
}

const makeGetInteractionCopyableActionsById = (interactionsId: string) => {
	return createSelector([getSelectedChatbot], (chatbot): ChatbotAction[] | undefined => {
		if (!chatbot || !chatbot.interactions) return undefined
		const interaction = chatbot.interactions.find((i) => i.id === interactionsId)
		if (!interaction) return undefined
		const { actions } = interaction

		const copyableActions = actions.map((action) => {
			const { id, ...rest } = action
			const actionWithoutId = rest

			// Remove id of the action and replies from the action data
			if (action.type === ChatbotActionType.Message && 'replies' in action.data) {
				const actionData = { ...action.data, replies: [] }
				return { ...actionWithoutId, data: actionData } as ChatbotActionMessage
			}
			return actionWithoutId
		})

		return copyableActions
	})
}

const makeGetInteractionLevelById = (interactionId: string) => {
	return createSelector([getSelectedChatbot], (chatbot): number => {
		if (!chatbot || !chatbot.interactions) return 0
		return getInteractionLevel(chatbot.interactions, interactionId)
	})
}

const isSelectedInteractionRootInteraction = createSelector(
	[getSelectedChatbot, chatbotBuilderSelectors.getSelectedInteractionId],
	(chatbot, selectedInteractionId): boolean => {
		if (!chatbot || !selectedInteractionId) return false
		return chatbot.interactions.findIndex(isInteractionById(selectedInteractionId)) === 0
	},
)

const getChatbotBuilderMovableNode = createSelector(
	[getChatbotBuilderNode, chatbotBuilderSelectors.getMoveNodeInteractionId],
	(builderNode, moveInteractionId): ChatbotBuilderMovableOrCopiableNode | null => {
		if (!builderNode || !moveInteractionId) return null
		try {
			return transformChatbotBuilderNodeToMovableNode(builderNode, moveInteractionId)
		} catch {
			flashMessage.error('general.error')
			return null
		}
	},
)

const getChatbotBuilderCopiableNode = createSelector(
	[getChatbotBuilderNode, chatbotBuilderSelectors.getCopyNodeModalData],
	(builderNode, copyNodeModalData): ChatbotBuilderMovableOrCopiableNode | null => {
		if (!builderNode || !copyNodeModalData || !copyNodeModalData.copyNodeInteractionId) return null
		try {
			return transformChatbotBuilderToCopiableNode(builderNode, copyNodeModalData.copyNodeInteractionId)
		} catch {
			flashMessage.error('general.error')
			return null
		}
	},
)

const getIsSelectedAutoMessage = createSelector([getSelectedChatbot], (selectedBot): boolean => {
	return selectedBot?.groupType === ChatbotGroupType.Message
})

const getIsSelectedLeadgenChatbot = createSelector([getSelectedChatbot], (selectedBot): boolean => {
	if (!selectedBot) return false
	return selectedBot.interactions.some((i) => findInteractionActionByType(i, ChatbotActionType.ContactRetrieveProperty))
})

const getSelectedChatbotGroupType = createSelector([getSelectedChatbot], (selectedBot): ChatbotGroupType => {
	return selectedBot?.groupType ?? ChatbotGroupType.Chatbot
})

const getIsSelectedAiChatbot = createSelector([getSelectedChatbot], (selectedBot): boolean => {
	if (!selectedBot) return false
	return selectedBot.groupType === ChatbotGroupType.Ai
})

const getIsInteractionInChatbot = createSelector(
	[getSelectedChatbot, chatbotBuilderSelectors.getSelectedInteractionId],
	(chatbot, interactionId): boolean => {
		if (!chatbot) return false
		return chatbot.interactions.some((interaction) => interaction.id === interactionId)
	},
)

const getIsChatbotDrawerOpened = createSelector(
	[
		chatbotBuilderSelectors.getIsChatbotBuilderDrawerOpen,
		chatbotStatisticsSelectors.getIsDrawerOpened,
		autoMessageStatisticsSelectors.getIsDrawerOpened,
		getSelectedSection,
		getIsSelectedAutoMessage,
	],
	(
		isBuilderDrawerOpened,
		isStatisticsDrawerOpened,
		isAutoMessageStatisticsDrawerOpened,
		selectedSection,
		isAutoMessage,
	): boolean => {
		if (selectedSection === SelectedChatbotSection.Design) {
			return isBuilderDrawerOpened
		}
		if (selectedSection === SelectedChatbotSection.Statistics) {
			if (isAutoMessage) {
				return isAutoMessageStatisticsDrawerOpened
			}
			return isStatisticsDrawerOpened
		}
		return false
	},
)

const hasUnsavedChanges = createSelector(
	[
		chatbotBuilderSelectors.getIsSelectedChatbotDirty,
		chatbotSettingsSelectors.getIsTriggerFormDirty,
		chatbotWorkflowSelectors.getIsWorkflowFormDirty,
	],
	(builderDirty, triggerDirty, workflowDirty): boolean => {
		return builderDirty || triggerDirty || workflowDirty
	},
)

const makeGetPropertiesByInteractionId = (interactionId: string) => {
	return createSelector([getSelectedChatbot], (chatbot) => {
		if (!chatbot || !chatbot.interactions) return null
		const interaction = chatbot.interactions.find((i) => i.id === interactionId)
		if (!interaction) return null
		return interaction.actions
			.filter((action) => action.type === ChatbotActionType.ContactRetrieveProperty)
			.map((action) => action.data)
			.map((actionData) => actionData.property)
	})
}

const getChatbotProperties = createSelector([getSelectedChatbot], (chatbot): string[] | null => {
	if (!chatbot) return null
	const chatbotProperties = chatbot.interactions
		.flatMap((interaction) => interaction.actions)
		.filter((action) => action.type === ChatbotActionType.ContactRetrieveProperty)
		.map((action) => action.data)
		.map((actionData) => actionData.property)
		.filter((property) => !getIsDefaultProperty(property))
	return chatbotProperties
})

const getChatbotTransferToBotIds = createSelector([getSelectedChatbot], (chatbot): string[] => {
	if (!chatbot) return []
	return chatbot.interactions
		.flatMap((interaction) => interaction.actions)
		.filter((action) => action.type === ChatbotActionType.BotTransfer)
		.map((action) => {
			const actionData = action.data
			return actionData.botId
		})
})

const getIsPublishButtonDisabled = createSelector(
	[
		chatbotSelectors.getCanActivateChatbot,
		autoMessagesSelectors.getCanActivateAutoMessage,
		getSelectedChatbotGroupType,
		getIsSelectedChatbotActive,
	],
	(canActivateChatbot, canActivateAutoMessage, groupType, isActive): boolean => {
		// Disable the button if the limit for respective chatbot group type is reached, AI chatbots has own selector
		const canActivate = () => {
			switch (groupType) {
				case ChatbotGroupType.Chatbot: {
					return canActivateChatbot
				}
				case ChatbotGroupType.Message: {
					return canActivateAutoMessage
				}
				default: {
					return true
				}
			}
		}

		return !canActivate() && !isActive
	},
)

const { actions, reducer } = chatbotDetailSlice
export const {
	deleteNodesByInteractionId,
	setIsChatbotActive,
	updateAppDataByInteractionId,
	updateRepliesByInteractionId,
	updateReplyTextByInteractionId,
	updateMessageByInteractionId,
	updateHandoverByInteractionId,
	updateGroupByInteractionId,
	updateBotTransferByInteractionId,
	updateRetrievePropertyActionsByInteractionId,
	updateAddableActionsByInteractionId,
	updateRequireApprovalByInteractionId,
	setSelectedChatbot,
	setSelectedSection,
	setPublishButtonPopover,
	setSelectedChatbotIdentityId,
} = actions
export default reducer

export const chatbotDetailSelectors = {
	getSelectedInteractionById,
	getUpdatedChatbot,
	getInteractionsReplies,
	getInteractionsGroup,
	getInteractionsBotTransferId,
	getInteractionsAppId,
	getInteractionsActionId,
	getSelectedAction,
	getSelectedSection,
	getCurrentInteractionsMessage,
	getInteractionsIndex,
	getIsChatbotCreatePending,
	getIsChatbotUpdatePending,
	getIsFetchingChatbot,
	getIsChatbotEdited,
	getSelectedChatbot,
	getChatbotTitle,
	getDataForFirstActionOfFirstInteraction,
	getTypeOfFirstActionOfSelectedInteraction,
	getRepliesToBeEdited,
	getIsSelectedChatbotActive,
	getIsSelectedAutoMessage,
	getSelectedChatbotGroupType,
	getChatbotBuilderNode,
	getChatbotId,
	getReplyTextByNextInteractionId,
	makeGetInteractionTypeById,
	isSelectedInteractionRootInteraction,
	getChatbotBuilderMovableNode,
	getChatbotBuilderCopiableNode,
	getSelectedChatbotTrigger,
	getChatbotInteractions,
	getIsFetchingChatbotActions,
	getChatbotActions,
	makeGetActionAppDataById,
	makeGetInteractionsBotTransferDataById,
	makeGetActionByIds,
	getIsInteractionInChatbot,
	getIsChatbotDrawerOpened,
	getPublishButtonPopoverType,
	getShowPublishButtonPopover,
	makeGetInteractionGroupById,
	getInteractionsAppData,
	getInteractionRetrievePropertyActions,
	makeGetHasInteractionActionsById,
	makeGetInteractionLevelById,
	makeGetInteractionCopyableActionsById,
	getInteractionsAddableActions,
	getInteractionContactRequireApprovalActionData,
	getIsSelectedAiChatbot,
	getIsSelectedLeadgenChatbot,
	hasUnsavedChanges,
	makeGetPropertiesByInteractionId,
	getChatbotProperties,
	getChatbotTransferToBotIds,
	getIsPublishButtonDisabled,
	getCurrentHandover,
}
