import { Children } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BoxProps } from '@chakra-ui/react'
import { mdiChevronDown } from '@mdi/js'

import { setCenterAiTabExpanded, usageSelectors } from 'modules/usage'

import { Box } from 'shared/components/core/Box'
import { CButton } from 'shared/components/core/CButton'
import { Icon } from 'shared/components/core/Icon'
import { Stack, StackDivider } from 'shared/components/core/Layout'
import { Text } from 'shared/components/core/Text'

type Props = {
	heading?: string
	itemsToShow?: number
	showMoreText?: string
	showLessText?: string
	scheme?: 'blue' | 'purple'
} & BoxProps

const DEFAULT_ITEMS_TO_SHOW = 3

const UsageCenterSection = ({
	children,
	heading,
	itemsToShow = DEFAULT_ITEMS_TO_SHOW,
	showMoreText,
	showLessText,
	scheme = 'blue',
	...rest
}: Props) => {
	const dispatch = useDispatch()
	const centerAiTabExpanded = useSelector(usageSelectors.getCenterAiTabExpanded)

	const childrenArray = Children.toArray(children)

	const visibleChildren = childrenArray.slice(0, itemsToShow)
	const hiddenChildren = childrenArray.slice(itemsToShow)

	const handleToggle = () => dispatch(setCenterAiTabExpanded(!centerAiTabExpanded))

	return (
		<Box px={3} py={2} {...rest}>
			{heading && (
				<Text fontSize="sm" fontWeight="semibold" textTransform="uppercase" color="gray.400" mb={1.5}>
					{heading}
				</Text>
			)}
			<Stack w="full" divider={<StackDivider borderColor="gray.200" />} gap={1}>
				{visibleChildren}

				{centerAiTabExpanded && hiddenChildren}
			</Stack>

			{childrenArray.length > itemsToShow && (
				<Box mt={2} textAlign="center">
					<CButton
						variant="link"
						size="sm"
						colorScheme={scheme}
						onClick={handleToggle}
						rightIcon={<Icon path={mdiChevronDown} rotate={centerAiTabExpanded ? 180 : 360} />}
					>
						{centerAiTabExpanded ? showLessText : showMoreText}
					</CButton>
				</Box>
			)}
		</Box>
	)
}

export default UsageCenterSection
