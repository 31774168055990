import { differenceInMilliseconds, isSameDay, isSameYear } from 'utils'

import IntlDate from './IntlDate'

type Props = {
	value: Date
}

const WEEK_IN_MS = 6048e5

export const AppDate = ({ value }: Props) => {
	const currentDate = new Date()
	const timeDiff = differenceInMilliseconds(currentDate, value)

	if (isSameDay(currentDate, value)) {
		return <IntlDate value={value} hour="numeric" minute="numeric" />
	}
	if (timeDiff < WEEK_IN_MS) {
		return <IntlDate value={value} weekday="short" hour="numeric" minute="numeric" />
	}
	if (isSameYear(currentDate, value)) {
		return <IntlDate value={value} month="short" day="numeric" />
	}
	return <IntlDate value={value} />
}
