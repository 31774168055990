import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { resolveTabByLocationPath, setCenterAiTabExpanded } from 'modules/usage'

import { CTabList, CTabPanels, CTabs } from 'shared/components/core/CTab'

import { UsageCenterAiTab, UsageCenterAiTabPanel } from './tabs/UsageCenterAiTab'
import { UsageCenterAutomationsTab, UsageCenterAutomationsTabPanel } from './tabs/UsageCenterAutomationsTab'
import { UsageCenterLivechatTab, UsageCenterLivechatTabPanel } from './tabs/UsageCenterLivechatTab'

const SidebarUsageCenterContent = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const [tabIndex, setTabIndex] = useState(0)

	const handleChangeTab = (index: number) => {
		setTabIndex(index)
		dispatch(setCenterAiTabExpanded(false))
	}

	useEffect(() => {
		const index = resolveTabByLocationPath(location.pathname)
		setTabIndex(index)
	}, [location])

	return (
		<CTabs index={tabIndex} onChange={handleChangeTab} isFitted>
			<CTabList>
				<UsageCenterLivechatTab />
				<UsageCenterAiTab />
				<UsageCenterAutomationsTab />
			</CTabList>

			<CTabPanels>
				<UsageCenterLivechatTabPanel />
				<UsageCenterAiTabPanel />
				<UsageCenterAutomationsTabPanel />
			</CTabPanels>
		</CTabs>
	)
}

export default SidebarUsageCenterContent
