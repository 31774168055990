import { useSelector } from 'react-redux'
import { PackageName } from '@smartsupp/billing-client-api'

import { TranslationService as T } from 'shared/services'
import { packageSelectors } from 'modules/package'
import { packagesSelectors } from 'modules/packages'
import { getPackageNameByUpsellType, upsellSelectors, UpsellType } from 'modules/upsell'

import { BillingButtonLink, BillingLinkType } from 'components/billing'
import { VStack } from 'shared/components/core/Layout'
import { Text } from 'shared/components/core/Text'

type UsageCenterOverLimitType =
	| 'livechatConversations'
	| 'aiReplyAssistants'
	| 'shortcuts'
	| 'agents'
	| 'aiConversations'
	| 'aiCustomResponses'
	| 'chatbotConversations'
	| 'autoMessages'
	| 'chatbots'

type UsageCenterOverLimitProps = {
	type: UsageCenterOverLimitType
	limit: number
}

const UsageCenterOverLimit = ({ type, limit }: UsageCenterOverLimitProps) => {
	const packageInfo = useSelector(packageSelectors.getPackageInfo)
	const checkoutPackageNameChatbotConversations = useSelector(
		packagesSelectors.getChatbotConversationsUpgradePackageName,
	)
	const checkoutPackageNameLivechatConversations = useSelector(
		packagesSelectors.getLivechatConversationsUpgradePackageName,
	)
	const checkoutPackageNameAiConversations = useSelector(packagesSelectors.getAiConversationsUpgradePackageName)
	const agentsUpgradePackageName = useSelector(packagesSelectors.getAgentsUpgradePackageName)

	const aiReplyAssistUpsellType = useSelector(upsellSelectors.getAiReplyAssistUpsellType)

	if (!packageInfo) return null

	const resolvePackageName = (): PackageName => {
		switch (type) {
			case 'livechatConversations': {
				return checkoutPackageNameLivechatConversations
			}
			case 'aiConversations': {
				return checkoutPackageNameAiConversations
			}
			case 'chatbotConversations': {
				return checkoutPackageNameChatbotConversations
			}
			case 'agents': {
				return agentsUpgradePackageName
			}
			case 'aiReplyAssistants': {
				return getPackageNameByUpsellType(aiReplyAssistUpsellType, packageInfo.name)
			}
			case 'chatbots': {
				return getPackageNameByUpsellType(UpsellType.Chatbots, packageInfo.name)
			}
			default: {
				return PackageName.Basic
			}
		}
	}

	const resolvePath = (): BillingLinkType => {
		if (packageInfo.isTrial || packageInfo.isFree) return 'packages'

		switch (type) {
			case 'shortcuts':
			case 'autoMessages': {
				return 'packages'
			}
			default: {
				return 'checkout'
			}
		}
	}

	const shouldShowUpgradeButton = !(packageInfo.isPro && type === 'aiReplyAssistants')

	return (
		<VStack alignItems="flex-start" fontSize="sm">
			<Text color="gray.500">{T.translate(`usageCenter.${type}.limitReached`, { limit: `${limit}` })}</Text>
			{shouldShowUpgradeButton && (
				<BillingButtonLink
					variant="link"
					size="sm"
					fontWeight="normal"
					to={resolvePath()}
					selectedPackage={resolvePackageName()}
				>
					{T.translate('upsellWindows.upgradePackage.button')}
				</BillingButtonLink>
			)}
		</VStack>
	)
}

export default UsageCenterOverLimit
