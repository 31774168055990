import {
	GatewayInfoTransferInvoice,
	GatewayInfoTransferOrder,
	PackageInfo,
	PackageInterval,
	PackageName,
	PackagesFeaturesTableV2 as PackagesFeaturesTableV2Orig,
	PackagesFeaturesTableV2Package as PackagesFeaturesTableV2PackageOrig,
} from '@smartsupp/billing-client-api'

import { ChatHistory } from './Account'
import { CurrencyCode } from './Currency'

export {
	GatewayInfo,
	GatewayInfoBraintree,
	GatewayInfoShopify,
	GatewayInfoTransfer,
	GatewayInfoTransferInvoice,
	GatewayInfoTransferOrder,
	PackageInfo,
	PackageInfoCustomization,
	PackageInfoFeatures,
	PackageInterval,
	PackageName,
	Package as PackagePricing,
	PackagesFeature,
	PackagesFeaturesTableV2PackageInclude,
	PackagesFeatureValue,
	PackageStatus,
} from '@smartsupp/billing-client-api'

export interface PackageNotifications {
	isDowngradedToFree: boolean
	isTrialExpiring: boolean
	isPackageExpiring: boolean
	isPackageExpired: boolean
	isPackagePastDue: boolean
	hasUnpaidFirstInvoice: boolean
	hasUnpaidGeneratedInvoice: boolean
}

export interface SelectedPackageInfo extends PackageInfo {
	formattedName: string
	isPaidPackage: boolean
	isTrial: boolean
	isFree: boolean
	isMini: boolean
	isBasic: boolean
	isPro: boolean
	isExpired: boolean
	isRemainingDaysCountLow: boolean
	isPastDue: boolean
	pendingInvoice: GatewayInfoTransferInvoice | null
	pendingOrder: GatewayInfoTransferOrder | null
	hasBraintreeGateway: boolean
	hasTransferGateway: boolean
	hasShopifyGateway: boolean
	hasAppleGateway: boolean
	hasStripeGateway: boolean
	isShopifySubscriptionUpdatable: boolean
	shopifyConfirmationUrl: string | null
	chatHistory: ChatHistory | null
	notifications: PackageNotifications
}

// Available package intervals - without legacy intervals
export type PackageIntervalAvailable = typeof PackageInterval.Month | typeof PackageInterval.Year

export type AllowedPaymentGateways = PackageInfo['allowedPaymentGateways']

export type PackagesPackageName = PackageName | typeof PackageNameUltimate

export enum PackageFeature {
	CustomWidget = 'hasEnabledCustomWidget',
	Facebook = 'hasEnabledFacebookConnect',
	OfficeHours = 'hasEnabledOfficeHours',
	ShortcutsTeam = 'hasEnabledTeamShortcuts',
	Statistics = 'hasEnabledStats',
	StatisticsBasic = 'hasEnabledStatsBasic',
	Tags = 'hasEnabledTags',
	VisitorEngagement = 'hasEnabledVisitorEngagement',
	ExportContacts = 'hasEnabledContactsExport',
	AccountReview = 'hasEnabledReviews',
	ChatbotActions = 'hasEnabledChatbotActions',
	ChatbotLeadgen = 'hasEnabledChatbotLeadgen',
	ContactProperties = 'hasEnabledContactsProperties',
}

export enum PackageLogLevel {
	Warn = 'warn',
	Info = 'info',
	Debug = 'debug',
}
export const PackageNameUltimate = 'ultimate'

export enum PackageLogAction {
	ThreeDSecurePayment = 'braintree:payment_3ds',
	ThreeDSecurePaymentError = 'braintree:payment_3ds_error',
	PaymentError = 'braintree:payment_error',
	UpdatePaymentMethodError = 'braintree:method_update_error',
	ShopifyPaymentError = 'shopify:payment_error',
	WireTransferPaymentError = 'wire_transfer:payment_error',
}

export enum PackagesType {
	Livechat = 'livechat',
	Ai = 'ai',
}

export interface PackageConfiguration {
	interval: PackageIntervalAvailable
	package: PackageName
	agents?: number
	chatbotConversations?: number
	livechatConversations?: number
	aiConversations?: number
}

export interface PackageConfigurationStripe {
	package: PackageName
	agents?: number
	chatbotConversations?: number
	livechatConversations?: number
	aiConversations?: number
}

export interface PackageBalance {
	balance: number
	currencyCode: CurrencyCode
	nextChargeDate: string
	nextChargeAmount: number
}

export interface PackageLogEventRequest {
	action: PackageLogAction
	message: string
	data?: Record<string, unknown>
	level?: string
}

export interface PackagesFeaturesTableV2Package extends PackagesFeaturesTableV2PackageOrig {
	code: PackagesPackageName
}

export interface PackagesFeaturesTableV2 extends PackagesFeaturesTableV2Orig {
	packages: PackagesFeaturesTableV2Package[]
}
