import { useSelector } from 'react-redux'

import { PackageName } from 'models'
import { TranslationService as T } from 'shared/services'
import { checkoutSelectors } from 'modules/checkout'
import { packageSelectors } from 'modules/package'
import { usageSelectors } from 'modules/usage'
import { userSelectors } from 'modules/user'

import { BillingButtonLink } from 'components/billing'
import { CButtonProps } from 'shared/components/core/CButton'

type Props = { selectedPackage?: PackageName } & CButtonProps

const UsageCenterPackageButton = ({ selectedPackage = PackageName.Basic, children, ...rest }: Props) => {
	const packageInfo = useSelector(packageSelectors.getPackageInfo)
	const isAdmin = useSelector(userSelectors.isUserAdmin)
	const { count } = useSelector(usageSelectors.getAiConversationsLimits)
	const maxCheckoutAiConversations = useSelector(checkoutSelectors.getMaxCheckoutAiConversations)
	const isAiOnMax = maxCheckoutAiConversations ? count >= maxCheckoutAiConversations : false
	const shouldShowManage = packageInfo?.isPro || isAiOnMax

	if (!isAdmin) return null

	return (
		<BillingButtonLink
			to={shouldShowManage ? 'profile' : 'packages'}
			size="sm"
			borderRadius="full"
			selectedPackage={selectedPackage}
			{...rest}
		>
			{children || (shouldShowManage ? T.translate('general.manage') : T.translate('upsell.upgrade.button'))}
		</BillingButtonLink>
	)
}

export default UsageCenterPackageButton
