import { useSelector } from 'react-redux'
import { mdiChatOutline, mdiGiftOutline, mdiRobotOutline, mdiSchoolOutline, mdiSourceBranch, mdiXml } from '@mdi/js'

import { PackageName, PackagesType } from 'models'
import { TranslationService as T } from 'shared/services'
import { isNumber } from 'shared/utils'
import { aiChatbotsSelectors } from 'modules/aiChatbots'
import { packageSelectors } from 'modules/package'
import { usageSelectors } from 'modules/usage'
import { usageSharedSelectors } from 'modules/usage/sharedSelectors'

import { Box } from 'shared/components/core/Box'
import { Image } from 'shared/components/core/Image'
import { HStack } from 'shared/components/core/Layout'
import { Text } from 'shared/components/core/Text'

import UsageCenterExpirationTag from '../parts/UsageCenterExpirationTag'
import UsageCenterLimitReset from '../parts/UsageCenterLimitReset'
import UsageCenterOverLimit from '../parts/UsageCenterOverLimit'
import UsageCenterPackageButton from '../parts/UsageCenterPackageButton'
import UsageCenterPackageDescription from '../parts/UsageCenterPackageDescription'
import UsageCenterProgress from '../parts/UsageCenterProgress'
import UsageCenterRow from '../parts/UsageCenterRow'
import UsageCenterSection from '../parts/UsageCenterSection'
import UsageCenterTab from '../parts/UsageCenterTab'
import UsageCenterTabPanel from '../parts/UsageCenterTabPanel'

const TAB_SCHEME = 'purple'

const UsageCenterAiTab = () => {
	const shouldShowWarning = useSelector(usageSharedSelectors.getShouldShowUsageCenterWarningDot)

	return (
		<UsageCenterTab scheme={TAB_SCHEME} showWarningDot={shouldShowWarning.ai}>
			{T.translate('general.miraAi')}
		</UsageCenterTab>
	)
}

const AiConversationsRow = () => {
	const aiConversationsLimits = useSelector(usageSelectors.getAiConversationsLimits)
	const { limit, count, isOverLimit, isOverThreshold75, isOverThreshold90, isOverThreshold95 } = aiConversationsLimits
	const isUnlimited = limit === null
	const shouldShowDesc = !isUnlimited && isNumber(limit) && limit > 0

	const description = shouldShowDesc ? (
		isOverLimit ? (
			<UsageCenterOverLimit type="aiConversations" limit={limit} />
		) : (
			<UsageCenterLimitReset />
		)
	) : null

	return (
		<UsageCenterRow
			title={T.translate('features.aiChatbotsConversations')}
			icon={mdiChatOutline}
			description={description}
			scheme={TAB_SCHEME}
			body={
				<UsageCenterProgress
					value={count}
					limit={limit}
					scheme={TAB_SCHEME}
					isUnlimited={isUnlimited}
					isDanger={isOverLimit}
					isWarning={isOverThreshold75 || isOverThreshold90 || isOverThreshold95}
				/>
			}
		/>
	)
}

const BonusAiConversationsRow = () => {
	const bonusAiConversationsLimits = useSelector(usageSelectors.getBonusAiConversationsLimits)
	const { limit, count } = bonusAiConversationsLimits

	return (
		<UsageCenterRow
			title={T.translate('features.aiBonusChatbotConversations')}
			icon={mdiGiftOutline}
			scheme={TAB_SCHEME}
			body={<UsageCenterProgress value={count} limit={limit} scheme={TAB_SCHEME} />}
		/>
	)
}

const AiChatbotsRow = () => {
	const aiBotsActiveCount = useSelector(aiChatbotsSelectors.getAiChatbotsActiveCount)
	const aiBotsLimit = useSelector(aiChatbotsSelectors.getAiBotsLimits)
	const isUnlimited = aiBotsLimit === null

	return (
		<UsageCenterRow
			title={T.translate('general.aiChatbots')}
			icon={mdiRobotOutline}
			scheme={TAB_SCHEME}
			body={
				<UsageCenterProgress
					value={aiBotsActiveCount}
					limit={aiBotsLimit.aiBotsTotalLimit}
					scheme={TAB_SCHEME}
					isUnlimited={isUnlimited}
				/>
			}
		/>
	)
}

const CustomResponsesRow = () => {
	const customResponseCountLimits = useSelector(usageSelectors.getCustomResponseCountLimits)
	const { limit, count } = customResponseCountLimits
	const isUnlimited = limit === null
	const shouldShowOverLimit = !isUnlimited && count >= limit

	return (
		<UsageCenterRow
			title={T.translate('ai.training.inLimit.title')}
			icon={mdiSchoolOutline}
			description={shouldShowOverLimit && <UsageCenterOverLimit type="aiCustomResponses" limit={limit} />}
			scheme={TAB_SCHEME}
			body={<UsageCenterProgress value={count} limit={limit} scheme={TAB_SCHEME} isUnlimited={isUnlimited} />}
		/>
	)
}

const WebScrapeSourceRow = () => {
	const aiSourcesCountLimits = useSelector(usageSelectors.getAiSourcesCountLimits)
	const { limit, count } = aiSourcesCountLimits.limits.web
	const isUnlimited = limit === null

	return (
		<UsageCenterRow
			title={T.translate('usageCenter.ai.webScrapeSource')}
			icon={mdiSourceBranch}
			scheme={TAB_SCHEME}
			body={<UsageCenterProgress value={count} limit={limit} scheme={TAB_SCHEME} isUnlimited={isUnlimited} />}
		/>
	)
}

const XmlFeedSourceRow = () => {
	const aiSourcesCountLimits = useSelector(usageSelectors.getAiSourcesCountLimits)
	const { limit, count } = aiSourcesCountLimits.limits.xml
	const isUnlimited = limit === null

	return (
		<UsageCenterRow
			title={T.translate('usageCenter.ai.xmlFeedSource')}
			icon={mdiXml}
			scheme={TAB_SCHEME}
			body={<UsageCenterProgress value={count} limit={limit} scheme={TAB_SCHEME} isUnlimited={isUnlimited} />}
		/>
	)
}

const UsageCenterAiTabPanel = () => {
	const packageInfo = useSelector(packageSelectors.getPackageInfo)
	const aiConversationsLimits = useSelector(usageSelectors.getAiConversationsLimits)

	const isAiConversationLimitZero = aiConversationsLimits.limit === 0
	const shouldShowLimitSection = !packageInfo?.isExpired

	return (
		<UsageCenterTabPanel>
			<UsageCenterSection heading={T.translate('usageCenter.packageInfo')}>
				<UsageCenterRow
					title={
						<HStack>
							<Box w="65px">
								<Image path="assets/logos/mira_ai.webp" alt={T.translate('general.shoppingAssistant')} />
							</Box>
							<Text mt={1}>{T.translate('general.shoppingAssistant')}</Text>
						</HStack>
					}
					titlePostfix={!isAiConversationLimitZero && <UsageCenterExpirationTag scheme={TAB_SCHEME} />}
					description={<UsageCenterPackageDescription packageType={PackagesType.Ai} />}
					scheme={TAB_SCHEME}
					body={
						<UsageCenterPackageButton colorScheme={TAB_SCHEME} selectedPackage={PackageName.Mini}>
							{isAiConversationLimitZero && T.translate('general.startNow')}
						</UsageCenterPackageButton>
					}
				/>
			</UsageCenterSection>
			{shouldShowLimitSection && (
				<UsageCenterSection
					heading={T.translate('usageCenter.currentUsage')}
					scheme={TAB_SCHEME}
					showMoreText={T.translate('usageCenter.ai.showMore')}
					showLessText={T.translate('usageCenter.showLess')}
				>
					<AiConversationsRow />
					<BonusAiConversationsRow />
					<AiChatbotsRow />
					<CustomResponsesRow />
					<WebScrapeSourceRow />
					<XmlFeedSourceRow />
				</UsageCenterSection>
			)}
		</UsageCenterTabPanel>
	)
}

export { UsageCenterAiTab, UsageCenterAiTabPanel }
