import { TabPanelProps } from '@chakra-ui/react'

import { CTabPanel } from 'shared/components/core/CTab'
import { Stack, StackDivider } from 'shared/components/core/Layout'

type Props = TabPanelProps

const UsageCenterTabPanel = ({ children, ...rest }: Props) => (
	<CTabPanel p={0} pb={1} {...rest}>
		<Stack spacing={0} w={['full', 530]} maxW="100%" divider={<StackDivider borderColor="gray.200" />}>
			{children}
		</Stack>
	</CTabPanel>
)

export default UsageCenterTabPanel
