import { AiChecklistUsage, AiUsage, UsageData } from 'models/Usage'
import { apiClient } from 'shared/utils'

const API_RESOURCE = 'usage'
const API_RESOURCE_AI = 'mira/usage'
const API_RESOURCE_AI_EXTRA = 'mira/extras'

const fetchUsage = () => {
	return apiClient.get<UsageData>(`${API_RESOURCE}`)
}

const fetchAiUsage = () => {
	return apiClient.get<AiUsage.Account>(`${API_RESOURCE_AI}`)
}

const fetchAiChecklist = () => {
	return apiClient.get<AiChecklistUsage>(`${API_RESOURCE_AI_EXTRA}/checklist`)
}

const getAiChecklistBonusConversations = () => {
	return apiClient.post(`${API_RESOURCE_AI_EXTRA}/checklist/bonus-conversations`)
}

export const usageApi = { fetchUsage, fetchAiUsage, fetchAiChecklist, getAiChecklistBonusConversations }
