import { PackageName } from 'models'

import {
	UpsellSubType,
	UpsellSubTypeAiConversations,
	UpsellSubTypeChatbotConversations,
	UpsellSubTypeLivechatConversations,
	UpsellType,
} from './constants'
import { UpsellAction } from './types'

export const getUpsellAction = (upsellType: UpsellType | null, subType?: UpsellSubType): UpsellAction | null => {
	if (!upsellType) return null

	switch (upsellType) {
		case UpsellType.AiTrainingEntries:
		case UpsellType.AccountReviewUnlimited:
		case UpsellType.AutoMessagesExtra:
		case UpsellType.ChatbotsExtra:
		case UpsellType.ChatHistoryDetailUnlimited:
		case UpsellType.AgentsAddUltimate: {
			return UpsellAction.Contact
		}
		case UpsellType.ChatbotConversations: {
			if (subType === UpsellSubTypeChatbotConversations.ToUltimate) return UpsellAction.Contact
			return UpsellAction.Upgrade
		}
		case UpsellType.AiConversations: {
			if (subType === UpsellSubTypeAiConversations.ToUltimate) return UpsellAction.Contact
			return UpsellAction.Upgrade
		}
		default: {
			return UpsellAction.Upgrade
		}
	}
}

const resolvePackageNameByCurrentPackage = (currentPackage: PackageName): PackageName => {
	return currentPackage === PackageName.Basic ? PackageName.Pro : PackageName.Basic
}

const getCurrentPackageName = (currentPackage: PackageName) => {
	return currentPackage === PackageName.Pro ? PackageName.Pro : PackageName.Basic
}

const getCurrentAiPackageName = (currentPackage: PackageName) => {
	return currentPackage === PackageName.Pro || currentPackage === PackageName.Basic ? currentPackage : PackageName.Mini
}

export const getPackageNameByUpsellType = (
	upsellType: UpsellType | null,
	currentPackage: PackageName,
	subType?: UpsellSubType,
): PackageName => {
	switch (upsellType) {
		case UpsellType.AiBonusConversationsLimit:
		case UpsellType.AiConversations: {
			return getCurrentAiPackageName(currentPackage)
		}
		case UpsellType.AgentsAdd: {
			return getCurrentPackageName(currentPackage)
		}
		case UpsellType.Chatbots:
		case UpsellType.AutoMessages: {
			return resolvePackageNameByCurrentPackage(currentPackage)
		}
		case UpsellType.AccountReview:
		case UpsellType.AiReplyAssistToPro:
		case UpsellType.ExportChats:
		case UpsellType.Statistics:
		case UpsellType.ChatHistoryMonths36:
		case UpsellType.ChatHistoryDetailMonths36:
		case UpsellType.Groups:
		case UpsellType.ShortcutsTeam:
		case UpsellType.OfficeHours:
		case UpsellType.ExportContacts:
		case UpsellType.ChatbotActions: {
			return PackageName.Pro
		}
		case UpsellType.ChatbotConversations: {
			if (subType === UpsellSubTypeChatbotConversations.ToPro) return PackageName.Pro
			return PackageName.Basic
		}
		case UpsellType.LivechatConversations: {
			if (subType === UpsellSubTypeLivechatConversations.ToPro) return PackageName.Pro
			return PackageName.Basic
		}
		default: {
			return PackageName.Basic
		}
	}
}
