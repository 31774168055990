import { useSelector } from 'react-redux'

import { TranslationService as T } from 'shared/services'
import { packageSelectors } from 'modules/package'
import { packageSharedSelectors } from 'modules/package/sharedSelectors'

import { IntlDate, IntlRelativeTime } from 'components/intl'
import { Tag } from 'shared/components/core/Tag'

type Props = {
	scheme?: 'blue' | 'purple'
}

const UsageCenterExpirationTag = ({ scheme = 'blue' }: Props) => {
	const packageInfo = useSelector(packageSelectors.getPackageInfo)
	const nextBillingDate = useSelector(packageSharedSelectors.getNextBillingDate)
	if (!packageInfo || !nextBillingDate) return null

	const { autoRenewal, billingPeriodEndDate, remainingDays, isExpired, isRemainingDaysCountLow } = packageInfo

	const isLastMonth = remainingDays <= 30

	// Don't show expiration info for unlimited packages
	if (!billingPeriodEndDate) return null

	let tagText
	if (isExpired) {
		tagText = <>{T.translate('notification.packageExpired.headline')}</>
	} else if (autoRenewal) {
		tagText = (
			<>
				{T.translate('billing.profile.package.renewsOn')}{' '}
				{isRemainingDaysCountLow ? (
					<IntlRelativeTime value={remainingDays} unit="day" />
				) : (
					<>
						{nextBillingDate && (
							<IntlDate value={new Date(nextBillingDate)} day="numeric" month="numeric" year="numeric" />
						)}
					</>
				)}
			</>
		)
	} else if (isLastMonth) {
		tagText = (
			<>
				{T.translate('billing.profile.package.ends')} <IntlRelativeTime value={remainingDays} unit="day" />
			</>
		)
	} else {
		tagText = (
			<>
				{T.translate('billing.profile.package.validUntil')}{' '}
				<IntlDate value={new Date(billingPeriodEndDate)} day="numeric" month="long" year="numeric" />
			</>
		)
	}

	return (
		<Tag size="md" colorScheme={scheme}>
			{tagText}
		</Tag>
	)
}

export default UsageCenterExpirationTag
