import { TabProps } from '@chakra-ui/react'

import { Box } from 'shared/components/core/Box'
import { CTab } from 'shared/components/core/CTab'

type Props = {
	scheme?: 'blue' | 'purple'
	showWarningDot?: boolean
} & TabProps

const UsageCenterTab = ({ children, scheme = 'blue', showWarningDot = false, ...rest }: Props) => (
	<CTab
		px={3}
		_selected={{ borderColor: `${scheme}.500`, color: `${scheme}.500` }}
		_hover={{ borderColor: `${scheme}.500` }}
		{...rest}
	>
		<Box position="relative">
			{showWarningDot && (
				<Box position="absolute" top="0" right="-12px" width="6px" height="6px" bgColor="red.600" borderRadius="full" />
			)}
			{children}
		</Box>
	</CTab>
)

export default UsageCenterTab
