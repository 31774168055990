export type FeatureUsageLimitsType = {
	count: number
	limit: number | null
	isOverThreshold75: boolean
	isOverThreshold90: boolean
	isOverThreshold95?: boolean
	isOverLimit?: boolean
}

export type MaxServedChatsLimitType = FeatureUsageLimitsType & {
	isOverThreshold95: boolean
	isOverLimit: boolean
	hasLastToLimit: boolean
	isAllowedNotificationInMonth: boolean
}

export type MaxChatbotConversationsLimitType = FeatureUsageLimitsType

export enum UsageCenterTabs {
	Automations = 'automations',
	Livechat = 'livechat',
	Ai = 'ai',
}

export const usageCenterTabIndex = {
	[UsageCenterTabs.Livechat]: 0,
	[UsageCenterTabs.Ai]: 1,
	[UsageCenterTabs.Automations]: 2,
}

// Items in map have order priority, last item has the highest priority
export const pathToUsageCenterTabMap = [
	{
		index: usageCenterTabIndex[UsageCenterTabs.Automations],
		substrings: ['automations'],
	},
	{
		index: usageCenterTabIndex[UsageCenterTabs.Ai],
		substrings: ['ai-automations'],
	},
]
