import { ReactNode } from 'react'

import { Box } from 'shared/components/core/Box'
import { Flex } from 'shared/components/core/Flex'
import { Icon } from 'shared/components/core/Icon'

type Props = {
	title: string | ReactNode
	icon?: string
	titlePostfix?: string | ReactNode
	description?: string | ReactNode
	body?: string | ReactNode
	scheme?: 'blue' | 'purple'
}

const UsageCenterRow = ({ title, icon, titlePostfix, description, body, scheme = 'blue' }: Props) => (
	<Flex w="full" alignItems="center" justifyContent="space-between" gap={2}>
		<Flex flexDirection="column" gap={0.5}>
			<Flex alignItems="center" gap={1} flexWrap="wrap">
				<Flex alignItems="center" gap={0.5}>
					{icon && <Icon path={icon} color={`${scheme}.500`} boxSize={5} />}
					<Box fontWeight="semibold">{title}</Box>
				</Flex>
				{titlePostfix}
			</Flex>
			{description}
		</Flex>
		<Box>{body}</Box>
	</Flex>
)

export default UsageCenterRow
