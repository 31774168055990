import { CircularProgressLabel } from '@chakra-ui/react'
import { mdiInformationSymbol } from '@mdi/js'

import { TranslationService as T } from 'shared/services'
import { isNumber } from 'shared/utils'

import { Flex } from 'shared/components/core/Flex'
import { Icon } from 'shared/components/core/Icon'
import { CircularProgress } from 'shared/components/core/Progress'
import { Text } from 'shared/components/core/Text'

type Props = {
	value?: number
	limit?: number | null
	scheme?: 'blue' | 'purple'
	isUnlimited?: boolean
	isDanger?: boolean
	isWarning?: boolean
}

const UsageCenterProgressUnlimited = () => <Text fontWeight="semibold">{T.translate('usageCenter.unlimited')}</Text>

const UsageCenterProgress = ({
	value,
	limit,
	scheme = 'blue',
	isUnlimited = false,
	isDanger = false,
	isWarning = false,
}: Props) => {
	if (isUnlimited) return <UsageCenterProgressUnlimited />
	if (!isNumber(limit) || !isNumber(value)) return null

	const percentage = Math.round((value / limit) * 100)
	const shouldShowProgress = limit !== 0

	const resolveProgressColor = (defaultColor: string) => {
		return isDanger ? 'red.500' : isWarning ? 'warning.500' : defaultColor
	}

	return (
		<Flex gap={1} alignItems="center">
			<Text fontWeight="semibold" color={resolveProgressColor('inherit')}>
				{value}/{limit}
			</Text>
			{shouldShowProgress && (
				<CircularProgress
					value={percentage}
					capIsRound
					color={resolveProgressColor(`${scheme}.500`)}
					size="32px"
					thickness="14px"
				>
					<CircularProgressLabel>
						{isDanger && <Icon path={mdiInformationSymbol} boxSize={6} color="red.500" rotate={180} />}
					</CircularProgressLabel>
				</CircularProgress>
			)}
		</Flex>
	)
}

export default UsageCenterProgress
