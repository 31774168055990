import { useSelector } from 'react-redux'
import { mdiAccountBoxOutline, mdiAccountMultipleOutline } from '@mdi/js'

import { TranslationService as T } from 'shared/services'
import { routes } from 'configuration/routes'
import { packageSelectors } from 'modules/package'
import { userSelectors } from 'modules/user'

import { PopoverMenu, PopoverMenuItem, PopoverMenuSection } from 'core'
import { SubmenuLinkContent } from 'components/layout/submenu/SubmenuLinkContent'
import RouterLink from 'components/router/RouterLink'

const SidebarCustomersMenu = () => {
	const isUserAdmin = useSelector(userSelectors.isUserAdmin)
	const packageInfo = useSelector(packageSelectors.getPackageInfo)

	const hasEnabledContacts = !!packageInfo?.features.hasEnabledContacts

	return (
		<PopoverMenu width="225px">
			<PopoverMenuSection noPadding noBorder>
				<PopoverMenuItem>
					<RouterLink to={routes.visitors.path} data-testid="submenu-customers-visitors">
						<SubmenuLinkContent icon={mdiAccountMultipleOutline}>
							{T.translate('headerBar.visitors')}
						</SubmenuLinkContent>
					</RouterLink>
				</PopoverMenuItem>
				{isUserAdmin && hasEnabledContacts && (
					<PopoverMenuItem>
						<RouterLink to={routes.contacts.path} data-testid="submenu-customers-contacts">
							<SubmenuLinkContent icon={mdiAccountBoxOutline}>{T.translate('features.contacts')}</SubmenuLinkContent>
						</RouterLink>
					</PopoverMenuItem>
				)}
			</PopoverMenuSection>
		</PopoverMenu>
	)
}

export default SidebarCustomersMenu
