import { createSelector } from '@reduxjs/toolkit'

import { packageSelectors } from 'modules/package/slice'
import { stripeSharedSelectors } from 'modules/stripe'

const getNextBillingDate = createSelector(
	[
		packageSelectors.getIsAllowedStripePaymentGateway,
		packageSelectors.getPackageInfo,
		stripeSharedSelectors.getStripeSubscription,
	],
	(isStripeAllowed, packageInfo, stripeSubscription) => {
		if (!isStripeAllowed || !packageInfo || !stripeSubscription) return null

		if (isStripeAllowed) {
			return stripeSubscription.nextBillingDate
		}

		return packageInfo.nextBillingDate
	},
)

export const packageSharedSelectors = {
	getNextBillingDate,
}
