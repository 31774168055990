import { batch } from 'react-redux'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import debounce from 'lodash/debounce'

import {
	PackageInterval,
	PackageName,
	StripeCustomerBalance,
	StripeInvoice,
	StripeSubscription,
	StripeSubscriptionCalculationResponse,
	StripeSubscriptionSaveRequestInterval,
} from 'models'
import { AppDispatch, AppThunkAction, DashboardState } from 'types'
import { flashMessage } from 'shared/utils'
import { createCheckoutLink } from 'configuration/routes'
import { ERROR_PROMO_CODE_INVALID } from 'constants/errors'
import { navigateTo, trackStripePromoCodeApplied } from 'utils'
import {
	checkoutSelectors,
	CheckoutStatusData,
	initCheckout,
	setCheckoutAgents,
	setCheckoutAiConversations,
	setCheckoutChatbotConversations,
	setCheckoutInterval,
	setCheckoutLivechatConversations,
	setCheckoutPaymentStatus,
	setInitialPackage,
} from 'modules/checkout'
import { packageSelectors } from 'modules/package'
import {
	createStripeSubscription,
	DEFAULT_DEBOUNCE_WAIT_MS,
	fetchChurnkeyAuth,
	fetchInvoice,
	fetchPaymentIntent,
	fetchStripeBalance,
	fetchStripeSubscription,
	hasCalculationDiscounts,
	minorToNormalPrice,
	resolveCalculationPromoCodeInterval,
	stripeSharedSelectors,
	transformInvoiceMinorUnitPrices,
	validatePromoCodeCalculate,
} from 'modules/stripe'

import { calculateStripeSubscription, setStripeSubscriptionAutoRenewal } from './actions'
import { transformMinorUnitPrices } from './utils'

export const initialState = {
	calculation: null as null | StripeSubscriptionCalculationResponse,
	subscription: null as null | StripeSubscription,
	balance: null as null | StripeCustomerBalance,
	calculationPendingCount: 0,
	isDebouncedCalculationPending: false,
	isRenewalPending: false,
	isRenewalPendingForPackageUpdate: false,
	promoCode: [] as string[],
	promoCodeInterval: null as null | StripeSubscriptionSaveRequestInterval,
	promoCodeErrorMessage: null as null | string,
	promoCodeToApply: null as null | string,
	isPromoCodeValidated: false,
	isValidatePromoCodePending: false,
	clientSecret: null as null | string,
	invoice: null as null | StripeInvoice,
	customerId: null as null | string,
	authHash: null as null | string,
	isChurnkeyAuthPending: false,
}

export type StripeRootState = Pick<DashboardState, 'stripe'>
export type StripeSlice = typeof initialState

export const stripeCalculate = (): AppThunkAction => async (dispatch, getState) => {
	const state = getState()
	const data = checkoutSelectors.getStripeSubscriptionCalculateData(state)
	const promoCodeToApply = stripeSharedSelectors.getStripePromoCodeToApply(state)
	const interval = checkoutSelectors.getCheckoutInterval(state)
	const isStripeAllowed = packageSelectors.getIsAllowedStripePaymentGateway(state)

	if (!isStripeAllowed) return

	const promoCodeCalculation = promoCodeToApply ? [promoCodeToApply] : (data.promoCodes ?? [])

	const resultAction = await dispatch(calculateStripeSubscription({ ...data, promoCodes: promoCodeCalculation }))
	if (calculateStripeSubscription.rejected.match(resultAction)) {
		if (resultAction.payload?.code === ERROR_PROMO_CODE_INVALID) {
			flashMessage.error('billing.promoCode.error.invalid', { testId: 'checkout-promo-code-failed' })
			dispatch(clearPromoCode())
			const result = await dispatch(calculateStripeSubscription({ ...data, promoCode: undefined }))
			if (calculateStripeSubscription.rejected.match(result)) {
				flashMessage.error('general.error')
			}
		} else {
			flashMessage.error('general.error')
		}
		dispatch(clearPromoCode())
	} else {
		dispatch(setPromoCode({ promoCode: promoCodeCalculation, validated: true }))
	}

	// validate applied promo code from URL param
	if (calculateStripeSubscription.fulfilled.match(resultAction) && promoCodeToApply) {
		const calculation = resultAction.payload
		const promoCodeInterval = resolveCalculationPromoCodeInterval(calculation)
		const isPromoCodeCalculationValid = hasCalculationDiscounts(calculation)

		if (isPromoCodeCalculationValid) {
			if (promoCodeInterval === StripeSubscriptionSaveRequestInterval.Month && interval !== PackageInterval.Month) {
				dispatch(setCheckoutInterval(PackageInterval.Month))
			} else if (
				promoCodeInterval === StripeSubscriptionSaveRequestInterval.Year &&
				interval !== PackageInterval.Year
			) {
				dispatch(setCheckoutInterval(PackageInterval.Year))
			}
			flashMessage.success('billing.promoCode.success', { testId: 'checkout-promo-code-success' })

			dispatch(setPromoCodeInterval(promoCodeInterval))
			dispatch(setPromoCode({ promoCode: [promoCodeToApply], validated: true }))
			dispatch(setPromoCodeToApply(null))
			promoCodeToApply && trackStripePromoCodeApplied(promoCodeToApply)
		} else {
			flashMessage.error('billing.promoCode.error.invalid', { testId: 'checkout-promo-code-failed' })
			dispatch(clearPromoCode())
		}
	}
}

export const stripeCalculateDebounced = (() => {
	const debouncedFunction = debounce((dispatch: AppDispatch) => {
		dispatch(stripeCalculate())
		dispatch(setIsDebouncedCalculationPending(false))
	}, DEFAULT_DEBOUNCE_WAIT_MS)

	return (dispatch: AppDispatch) => {
		dispatch(setIsDebouncedCalculationPending(true))
		debouncedFunction(dispatch)
	}
})()

export const validateAndApplyPromoCode =
	(promoCode: string): AppThunkAction =>
	async (dispatch, getState) => {
		const state = getState()
		const interval = checkoutSelectors.getCheckoutInterval(state)
		const data = checkoutSelectors.getStripeSubscriptionCalculateData(state)

		dispatch(setPromoCodeErrorMessage(null))

		const resultAction = await dispatch(validatePromoCodeCalculate({ ...data, promoCodes: [promoCode] }))

		if (validatePromoCodeCalculate.rejected.match(resultAction)) {
			if (resultAction.payload?.code === ERROR_PROMO_CODE_INVALID) {
				dispatch(setPromoCodeErrorMessage('billing.promoCode.error.invalid'))
			} else {
				flashMessage.error('general.error')
			}
		} else {
			const calculation = resultAction.payload
			const promoCodeInterval = resolveCalculationPromoCodeInterval(calculation)
			const isPromoCodeCalculationValid = hasCalculationDiscounts(calculation)

			if (promoCodeInterval === StripeSubscriptionSaveRequestInterval.Month && interval !== PackageInterval.Month) {
				dispatch(setPromoCodeErrorMessage('billing.promoCode.error.invalidYearly'))
			} else if (
				promoCodeInterval === StripeSubscriptionSaveRequestInterval.Year &&
				interval !== PackageInterval.Year
			) {
				dispatch(setPromoCodeErrorMessage('billing.promoCode.error.invalidMonthly'))
			} else if (isPromoCodeCalculationValid) {
				flashMessage.success('billing.promoCode.success', { testId: 'checkout-promo-code-success' })

				batch(() => {
					dispatch(setPromoCode({ promoCode: [promoCode], validated: true }))
					dispatch(setPromoCodeInterval(promoCodeInterval))
					dispatch(setCalculation(transformMinorUnitPrices(calculation)))
				})

				trackStripePromoCodeApplied(promoCode)
			} else {
				dispatch(setPromoCodeErrorMessage('billing.promoCode.error.invalid'))
			}
		}
	}

export const fetchUnpaidInvoiceClientSecret = (): AppThunkAction => async (dispatch) => {
	const subscriptionResult = await dispatch(fetchStripeSubscription())

	if (fetchStripeSubscription.fulfilled.match(subscriptionResult)) {
		const { unpaidInvoiceId } = subscriptionResult.payload

		if (unpaidInvoiceId) {
			const invoiceResult = await dispatch(fetchInvoice(unpaidInvoiceId))

			if (fetchInvoice.fulfilled.match(invoiceResult)) {
				const { paymentIntentId } = invoiceResult.payload

				if (paymentIntentId) {
					dispatch(fetchPaymentIntent(paymentIntentId))
				}
			}
		}
	}
}

export const openCheckoutPaymentResult =
	(
		packageName: PackageName,
		status: CheckoutStatusData,
		promoCode: string[],
		interval: PackageInterval | null,
		agents: number | null,
		chatbotConversations: number | null,
		livechatConversations: number | null,
		aiConversations: number | null,
		initialPackage: PackageName | null,
		initialAiConversations: number | null,
	): AppThunkAction =>
	(dispatch) => {
		dispatch(initCheckout())
		dispatch(setCheckoutPaymentStatus(status))
		promoCode && dispatch(setPromoCode({ promoCode, validated: false }))
		interval && dispatch(setCheckoutInterval(interval))
		agents && dispatch(setCheckoutAgents(agents))
		chatbotConversations && dispatch(setCheckoutChatbotConversations(chatbotConversations))
		livechatConversations && dispatch(setCheckoutLivechatConversations(livechatConversations))
		aiConversations !== null && dispatch(setCheckoutAiConversations(aiConversations))
		initialPackage &&
			dispatch(setInitialPackage({ packageName: initialPackage, aiConversations: initialAiConversations }))
		navigateTo(createCheckoutLink(packageName))
	}

const slice = createSlice({
	name: 'stripe',
	initialState,
	reducers: {
		setCalculation: (state, { payload }: PayloadAction<StripeSubscriptionCalculationResponse>) => {
			state.calculation = payload
		},
		setPromoCode: (state, { payload }: PayloadAction<{ promoCode: string[]; validated: boolean }>) => {
			state.promoCode = payload.promoCode
			state.isPromoCodeValidated = payload.validated
		},
		setPromoCodeToApply: (state, { payload }: PayloadAction<string | null>) => {
			state.promoCodeToApply = payload
		},
		setPromoCodeErrorMessage: (state, { payload }: PayloadAction<string | null>) => {
			state.promoCodeErrorMessage = payload
		},
		setPromoCodeInterval: (state, { payload }: PayloadAction<StripeSubscriptionSaveRequestInterval | null>) => {
			state.promoCodeInterval = payload
		},
		clearPromoCode: (state) => {
			state.promoCode = []
			state.promoCodeToApply = null
		},
		clearClientSecret: (state) => {
			state.clientSecret = null
		},
		setIsRenewalPendingForPackageUpdate: (state, { payload }: PayloadAction<boolean>) => {
			state.isRenewalPendingForPackageUpdate = payload
		},
		setIsDebouncedCalculationPending: (state, { payload }: PayloadAction<boolean>) => {
			state.isDebouncedCalculationPending = payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(calculateStripeSubscription.pending, (state) => {
				state.calculationPendingCount += 1
			})
			.addCase(calculateStripeSubscription.fulfilled, (state, { payload }) => {
				state.calculation = transformMinorUnitPrices(payload)
				state.calculationPendingCount -= 1
				state.isPromoCodeValidated = true
			})
			.addCase(calculateStripeSubscription.rejected, (state) => {
				state.calculationPendingCount -= 1
				state.isPromoCodeValidated = true
			})

		builder
			.addCase(setStripeSubscriptionAutoRenewal.pending, (state) => {
				state.isRenewalPending = true
				state.isRenewalPendingForPackageUpdate = true
			})
			.addCase(setStripeSubscriptionAutoRenewal.fulfilled, (state) => {
				state.isRenewalPending = false
			})
			.addCase(setStripeSubscriptionAutoRenewal.rejected, (state) => {
				state.isRenewalPending = false
				state.isRenewalPendingForPackageUpdate = false
			})

		builder
			.addCase(validatePromoCodeCalculate.pending, (state) => {
				state.isValidatePromoCodePending = true
			})
			.addCase(validatePromoCodeCalculate.fulfilled, (state) => {
				state.isValidatePromoCodePending = false
			})
			.addCase(validatePromoCodeCalculate.rejected, (state) => {
				state.isValidatePromoCodePending = false
			})

		builder.addCase(fetchStripeSubscription.fulfilled, (state, { payload }) => {
			state.subscription = payload
		})

		builder.addCase(fetchStripeBalance.fulfilled, (state, { payload }) => {
			const { currency, balance } = payload
			state.balance = {
				currency,
				balance: minorToNormalPrice(balance) * -1,
			}
		})

		builder.addCase(createStripeSubscription.fulfilled, (state, { payload }) => {
			state.clientSecret = payload.stripeClientSecret
		})

		builder.addCase(fetchInvoice.fulfilled, (state, { payload }) => {
			state.invoice = transformInvoiceMinorUnitPrices(payload)
		})

		builder.addCase(fetchPaymentIntent.fulfilled, (state, { payload }) => {
			state.clientSecret = payload.clientSecret
		})

		builder
			.addCase(fetchChurnkeyAuth.pending, (state) => {
				state.isChurnkeyAuthPending = true
			})
			.addCase(fetchChurnkeyAuth.fulfilled, (state, { payload }) => {
				state.isChurnkeyAuthPending = false
				state.authHash = payload.authHash
				state.customerId = payload.customerId
			})
			.addCase(fetchChurnkeyAuth.rejected, (state) => {
				state.isChurnkeyAuthPending = false
			})
	},
})

const { reducer, actions } = slice
export const {
	setCalculation,
	setPromoCode,
	setPromoCodeErrorMessage,
	setPromoCodeInterval,
	clearPromoCode,
	setPromoCodeToApply,
	clearClientSecret,
	setIsRenewalPendingForPackageUpdate,
	setIsDebouncedCalculationPending,
} = actions
export default reducer
