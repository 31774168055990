import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AgentEvents } from '@smartsupp/websocket-client-agent'

import { AppDispatch, AppThunkAction, DashboardState } from 'types'
import { AiChecklistUsage, AiUsage } from 'shared/models/Usage'
import { isNumber } from 'shared/utils'
import { updateDataLayerMonthlyServed } from 'modules/dataLayer'
import { packageSelectors } from 'modules/package'
import { SourceFormType } from 'modules/sources'
import {
	hideProductNotification,
	notificationSelectors,
	ProductNotificationType,
	showProductNotification,
} from 'modules/systemNotification'
import { isNotificationAllowed } from 'modules/systemNotification/utils'
import { calculateUsageLimits, isAllowedNotificationInMonth } from 'modules/usage/utils'

import { usageApi } from './api'
import {
	FeatureUsageLimitsType,
	MaxChatbotConversationsLimitType,
	MaxServedChatsLimitType,
	UsageCenterTabs,
} from './types'

export const fetchUsage = createAsyncThunk('usage/FETCH', async (_, thunkAPI) => {
	try {
		const response = await usageApi.fetchUsage()
		const dispatch = thunkAPI.dispatch as AppDispatch
		dispatch(updateDataLayerMonthlyServed(response.monthly_served_conversations.value))
		return response
	} catch (error) {
		return thunkAPI.rejectWithValue(error)
	}
})

export const fetchAiUsage = createAsyncThunk('usage/FETCH_AI', async () => {
	return usageApi.fetchAiUsage()
})

export const fetchAiChecklist = createAsyncThunk('usage/FETCH_AI_CHECKLIST', async () => {
	return usageApi.fetchAiChecklist()
})

export const awardAiChecklistConversations = createAsyncThunk('usage/AWARD_AI_CHECKLIST_CONVERSATIONS', async () => {
	return usageApi.getAiChecklistBonusConversations()
})

export const updateMonthlyServedConversations =
	(count: number): AppThunkAction =>
	(dispatch) => {
		dispatch(setMonthlyServed(count))
		dispatch(updateDataLayerMonthlyServed(count))
		dispatch(checkUsageLimits())
	}

export const checkUsageLimits = (): AppThunkAction => (dispatch, getState) => {
	const state = getState()
	const chatbotConversations = getChatbotConversationsLimits(state)
	const livechatConversations = getMonthlyServedLimits(state)
	const aiConversations = getAiConversationsLimits(state)
	const aiBonusConversations = getBonusAiConversationsLimits(state)

	const hasProductNotification = !!notificationSelectors.getProductNotificationType(state)

	// Livechat conversations
	if (
		livechatConversations.isOverLimit &&
		isNotificationAllowed(ProductNotificationType.LivechatConversationLimitReached, livechatConversations.limit)
	) {
		dispatch(showProductNotification(ProductNotificationType.LivechatConversationLimitReached))
		return
	}
	if (
		livechatConversations.isOverThreshold95 &&
		isNotificationAllowed(ProductNotificationType.LivechatConversationOver95, livechatConversations.limit)
	) {
		dispatch(showProductNotification(ProductNotificationType.LivechatConversationOver95))
		return
	}
	if (
		livechatConversations.isOverThreshold90 &&
		isNotificationAllowed(ProductNotificationType.LivechatConversationOver90, livechatConversations.limit) &&
		livechatConversations.isAllowedNotificationInMonth
	) {
		dispatch(showProductNotification(ProductNotificationType.LivechatConversationOver90))
		return
	}
	if (
		livechatConversations.isOverThreshold75 &&
		isNotificationAllowed(ProductNotificationType.LivechatConversationOver75, livechatConversations.limit) &&
		livechatConversations.isAllowedNotificationInMonth
	) {
		dispatch(showProductNotification(ProductNotificationType.LivechatConversationOver75))
		return
	}

	// AI conversations
	if (
		aiConversations.isOverLimit &&
		isNotificationAllowed(ProductNotificationType.AiConversationLimitReached, aiConversations.limit)
	) {
		dispatch(showProductNotification(ProductNotificationType.AiConversationLimitReached))
		return
	}
	if (
		aiConversations.isOverThreshold90 &&
		isNotificationAllowed(ProductNotificationType.AiConversationOver90, aiConversations.limit)
	) {
		dispatch(showProductNotification(ProductNotificationType.AiConversationOver90))
		return
	}
	if (
		aiConversations.isOverThreshold75 &&
		isNotificationAllowed(ProductNotificationType.AiConversationOver75, aiConversations.limit)
	) {
		dispatch(showProductNotification(ProductNotificationType.AiConversationOver75))
		return
	}
	if (
		aiBonusConversations.isOverLimit &&
		aiConversations.limit === 0 &&
		isNotificationAllowed(ProductNotificationType.AiBonusConversationLimitReached, aiBonusConversations.limit)
	) {
		dispatch(showProductNotification(ProductNotificationType.AiBonusConversationLimitReached))
		return
	}

	// Chatbot conversations
	if (
		chatbotConversations.isOverLimit &&
		isNotificationAllowed(ProductNotificationType.ChatbotConversationLimitReached, chatbotConversations.limit)
	) {
		dispatch(showProductNotification(ProductNotificationType.ChatbotConversationLimitReached))
		return
	}
	if (
		chatbotConversations.isOverThreshold90 &&
		isNotificationAllowed(ProductNotificationType.ChatbotConversationOver90, chatbotConversations.limit)
	) {
		dispatch(showProductNotification(ProductNotificationType.ChatbotConversationOver90))
		return
	}
	if (
		chatbotConversations.isOverThreshold75 &&
		isNotificationAllowed(ProductNotificationType.ChatbotConversationOver75, chatbotConversations.limit)
	) {
		dispatch(showProductNotification(ProductNotificationType.ChatbotConversationOver75))
	}

	// Hide notification if it becomes irrelevant after the usage changes
	else if (hasProductNotification) {
		dispatch(hideProductNotification())
	}
}

export const aiReplyAssistCounterIncrement = (): AppThunkAction => (dispatch, getState) => {
	const currentCounter = getAiReplyAssistCounter(getState())
	const newCounter = Number(currentCounter) + 1
	dispatch(setAiReplyAssistCounter(newCounter))
}

export type UsageState = typeof initialState
export type UsageRootState = Pick<DashboardState, 'usage'>

const initialState = {
	monthlyServedConversations: 0,
	chatbotConversations: 0,
	aiReplyAssistCounter: 0,
	aiConversations: 0,
	aiUsage: null as null | AiUsage.Account,
	aiChecklist: {
		aiChecklistUsage: null as null | AiChecklistUsage,
		isFetchingAiChecklist: true,
		isGettingBonusConversations: false,
		isCompleted: false,
	},
	centerActiveTab: UsageCenterTabs.Livechat as UsageCenterTabs,
	centerAiTabExpanded: false,
}

const usageSlice = createSlice({
	name: 'usage',
	initialState,
	reducers: {
		setMonthlyServed: (state, { payload }: PayloadAction<number>) => {
			state.monthlyServedConversations = payload
		},
		setChatbotConversations: (state, { payload }: PayloadAction<number>) => {
			state.chatbotConversations = payload
		},
		setAiReplyAssistCounter: (state, { payload }: PayloadAction<number>) => {
			state.aiReplyAssistCounter = payload
		},
		setAiConversations: (state, { payload }: PayloadAction<{ limit: number | null; usage: number }>) => {
			if (state.aiUsage) {
				state.aiUsage.conversations.used = payload.usage
				if (isNumber(payload.limit)) state.aiUsage.conversations.limit = payload.limit
			}
		},
		setAiBonusConversations: (state, { payload }: PayloadAction<{ limit: number | null; usage: number }>) => {
			if (state.aiUsage) {
				state.aiUsage.bonusConversations.used = payload.usage
				if (isNumber(payload.limit)) state.aiUsage.bonusConversations.limit = payload.limit
			}
		},
		setAiSourceFeedCount: (state, { payload }: PayloadAction<{ limit: number | null; usage: number }>) => {
			if (state.aiUsage) {
				state.aiUsage.sourceFeeds.used = payload.usage
				if (isNumber(payload.limit)) state.aiUsage.sourceFeeds.limit = payload.limit
			}
		},
		setAiSourceWebCount: (state, { payload }: PayloadAction<{ limit: number | null; usage: number }>) => {
			if (state.aiUsage) {
				state.aiUsage.sourceWebsites.used = payload.usage
				if (isNumber(payload.limit)) state.aiUsage.sourceWebsites.limit = payload.limit
			}
		},
		setAiSourceFeedData: (state, { payload }: PayloadAction<{ limit: number | null; usage: number }>) => {
			if (state.aiUsage) {
				state.aiUsage.sourceFeedData.used = payload.usage
				if (isNumber(payload.limit)) state.aiUsage.sourceFeedData.limit = payload.limit
			}
		},
		setAiSourceWebData: (state, { payload }: PayloadAction<{ limit: number | null; usage: number }>) => {
			if (state.aiUsage) {
				state.aiUsage.sourceWebsiteData.used = payload.usage
				if (isNumber(payload.limit)) state.aiUsage.sourceWebsiteData.limit = payload.limit
			}
		},
		setAiPreviewMessages: (state, { payload }: PayloadAction<{ limit: number | null; usage: number }>) => {
			if (state.aiUsage) {
				state.aiUsage.previewMessages.used = payload.usage
				if (isNumber(payload.limit)) state.aiUsage.previewMessages.limit = payload.limit
			}
		},
		setAiCustomResponses: (state, { payload }: PayloadAction<{ limit: number | null; usage: number }>) => {
			if (state.aiUsage) {
				state.aiUsage.customResponseCount.used = payload.usage
				if (isNumber(payload.limit)) state.aiUsage.customResponseCount.limit = payload.limit
			}
		},
		setAiChecklistFeedCompleted: (state) => {
			if (state.aiChecklist.aiChecklistUsage) {
				state.aiChecklist.aiChecklistUsage.feedCount = state.aiChecklist.aiChecklistUsage.feedCountLimit
			}
		},
		setAiChecklistData: (state, { payload }: PayloadAction<AgentEvents.AiExtrasChecklistUpdated>) => {
			const properties = ['customResponseCount', 'feedCount', 'websiteCount', 'publishedChatbotCount'] as const

			properties.forEach((property) => {
				if (isNumber(payload[property]) && state.aiChecklist.aiChecklistUsage)
					state.aiChecklist.aiChecklistUsage[property] = payload[property]
			})
		},
		setCenterActiveTab: (state, { payload }: PayloadAction<UsageCenterTabs>) => {
			state.centerActiveTab = payload
		},
		setCenterAiTabExpanded: (state, { payload }: PayloadAction<boolean>) => {
			state.centerAiTabExpanded = payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchUsage.fulfilled, (state, { payload }) => {
			state.monthlyServedConversations = payload.served_conversation.value
			state.chatbotConversations = payload.bot_conversation.value
			state.aiReplyAssistCounter = payload.ai_assist.value
			state.aiConversations = payload.ai_conversation.value
		})
		builder.addCase(fetchAiUsage.fulfilled, (state, { payload }) => {
			state.aiUsage = payload
		})
		builder.addCase(fetchAiChecklist.fulfilled, (state, { payload }) => {
			state.aiChecklist.aiChecklistUsage = payload
			state.aiChecklist.isFetchingAiChecklist = false
		})
		builder.addCase(fetchAiChecklist.pending, (state) => {
			state.aiChecklist.isFetchingAiChecklist = true
		})
		builder.addCase(fetchAiChecklist.rejected, (state) => {
			state.aiChecklist.isFetchingAiChecklist = false
		})
		builder.addCase(awardAiChecklistConversations.fulfilled, (state) => {
			state.aiChecklist.isGettingBonusConversations = false
			state.aiChecklist.isCompleted = true
		})
		builder.addCase(awardAiChecklistConversations.pending, (state) => {
			state.aiChecklist.isGettingBonusConversations = true
		})
		builder.addCase(awardAiChecklistConversations.rejected, (state) => {
			state.aiChecklist.isGettingBonusConversations = false
		})
	},
})

const { reducer, actions } = usageSlice
export const {
	setMonthlyServed,
	setChatbotConversations,
	setAiConversations,
	setAiBonusConversations,
	setAiReplyAssistCounter,
	setAiSourceFeedCount,
	setAiSourceWebCount,
	setAiSourceFeedData,
	setAiSourceWebData,
	setAiPreviewMessages,
	setAiCustomResponses,
	setAiChecklistFeedCompleted,
	setAiChecklistData,
	setCenterActiveTab,
	setCenterAiTabExpanded,
} = actions
export default reducer

const getMonthlyServed = (state: UsageRootState) => state.usage.monthlyServedConversations
const getChatbotConversations = (state: UsageRootState) => state.usage.chatbotConversations
const getAiReplyAssistCounter = (state: UsageRootState) => state.usage.aiReplyAssistCounter
const getAiUsage = (state: UsageRootState) => state.usage.aiUsage
const getAiChecklistUsage = (state: UsageRootState) => state.usage.aiChecklist.aiChecklistUsage
const getIsFetchingAiChecklist = (state: UsageRootState) => state.usage.aiChecklist.isFetchingAiChecklist
const getIsAiChecklistCompleted = (state: UsageRootState) => state.usage.aiChecklist.isCompleted
const getIsAiChecklistGettingBonusConversations = (state: UsageRootState) =>
	state.usage.aiChecklist.isGettingBonusConversations
const getCenterActiveTab = (state: UsageRootState) => state.usage.centerActiveTab
const getCenterAiTabExpanded = (state: UsageRootState) => state.usage.centerAiTabExpanded

const getMonthlyServedLimits = createSelector(
	[getMonthlyServed, packageSelectors.getPackageInfo],
	(monthlyServed, packageInfo): MaxServedChatsLimitType => {
		const limit = packageInfo?.livechatConversationsLimit ?? null
		const hasLastToLimit = limit ? limit === monthlyServed + 1 : false

		const baseObject = calculateUsageLimits(monthlyServed, limit, ['75', '90', '95'])

		return {
			...baseObject,
			hasLastToLimit,
			isAllowedNotificationInMonth: limit ? isAllowedNotificationInMonth(monthlyServed, limit) : false,
		} as MaxServedChatsLimitType
	},
)

const getChatbotConversationsLimits = createSelector(
	[getChatbotConversations, packageSelectors.getPackageInfo],
	(count, packageInfo): MaxChatbotConversationsLimitType => {
		const limit = packageInfo?.chatbotConversationsLimit ?? null

		return calculateUsageLimits(count, limit, ['75', '90'])
	},
)

const getAiConversationsLimits = createSelector([getAiUsage], (aiUsage): FeatureUsageLimitsType => {
	const count = aiUsage?.conversations.used ?? 0
	const limit = aiUsage?.conversations.limit ?? null

	return calculateUsageLimits(count, limit, ['75', '90'])
})

const shouldShowChatbotConversationsLimitAlert = createSelector(
	[getChatbotConversationsLimits],
	({ isOverLimit, isOverThreshold75, isOverThreshold90 }): boolean => {
		return isOverLimit || isOverThreshold75 || isOverThreshold90
	},
)

const getAiReplyAssistLimits = createSelector(
	[getAiReplyAssistCounter, packageSelectors.getPackageInfo],
	(count, packageInfo): FeatureUsageLimitsType => {
		const limit = packageInfo?.aiAssistsLimit ?? 0

		return calculateUsageLimits(count, limit, ['75', '90'])
	},
)

const getBonusAiConversationsLimits = createSelector([getAiUsage], (aiUsage): FeatureUsageLimitsType => {
	const count = aiUsage?.bonusConversations.used ?? 0
	const limit = aiUsage?.bonusConversations.limit ?? null

	return calculateUsageLimits(count, limit, ['75', '90'])
})

const getAiSourcesCountLimits = createSelector(
	[getAiUsage],
	(
		aiUsage,
	): {
		limits: Record<SourceFormType, { isReached: boolean; count: number; limit: number | null }>
		isLimitReached: boolean
	} => {
		const limitFeeds = aiUsage?.sourceFeeds.limit ?? null
		const limitWebsites = aiUsage?.sourceWebsites.limit ?? null

		const countSourcesFeeds = aiUsage?.sourceFeeds.used ?? 0
		const countSourcesWebsites = aiUsage?.sourceWebsites.used ?? 0

		const isFeedsLimitReached = limitFeeds === null ? false : countSourcesFeeds >= limitFeeds
		const isWebsitesLimitReached = limitWebsites === null ? false : countSourcesWebsites >= limitWebsites

		return {
			limits: {
				[SourceFormType.Xml]: { isReached: isFeedsLimitReached, count: countSourcesFeeds, limit: limitFeeds },
				[SourceFormType.Web]: { isReached: isWebsitesLimitReached, count: countSourcesWebsites, limit: limitWebsites },
			},
			isLimitReached: isWebsitesLimitReached && isFeedsLimitReached,
		}
	},
)

const getAiSourcesDataLimits = createSelector(
	[getAiUsage],
	(
		aiUsage,
	): {
		limits: Record<SourceFormType, { isReached: boolean }>
		isLimitReached: boolean
	} => {
		const limitFeeds = aiUsage?.sourceFeedData.limit ?? null
		const limitWebsites = aiUsage?.sourceWebsiteData.limit ?? null

		const dataSourcesFeeds = aiUsage?.sourceFeedData.used ?? 0
		const dataSourcesWebsites = aiUsage?.sourceWebsiteData.used ?? 0

		const isFeedsLimitReached = limitFeeds === null ? false : dataSourcesFeeds >= limitFeeds
		const isWebsitesLimitReached = limitWebsites === null ? false : dataSourcesWebsites >= limitWebsites

		return {
			limits: {
				[SourceFormType.Xml]: { isReached: isFeedsLimitReached },
				[SourceFormType.Web]: { isReached: isWebsitesLimitReached },
			},
			isLimitReached: isWebsitesLimitReached && isFeedsLimitReached,
		}
	},
)

const getIsAiPreviewLimitReached = createSelector([getAiUsage], (aiUsage): boolean => {
	const limitPreviewMessages = aiUsage?.previewMessages.limit ?? null
	const usagePreviewMessages = aiUsage?.previewMessages.used ?? 0

	return limitPreviewMessages ? usagePreviewMessages >= limitPreviewMessages : false
})

const getAiSourcesDataLimitsInB = createSelector([getAiUsage], (aiUsage) => {
	const limitFeedsInB = aiUsage?.sourceFeedData.limit ?? 0
	const limitWebsitesInB = aiUsage?.sourceWebsiteData.limit ?? 0

	return {
		[SourceFormType.Xml]: limitFeedsInB,
		[SourceFormType.Web]: limitWebsitesInB,
	}
})

const getAiSourcesLimitDataFeeds = createSelector([getAiUsage], (aiUsage) => {
	return aiUsage?.sourceFeedData.limit ?? 0
})

const getAiTrainingEntriesLimit = createSelector([getAiUsage], (aiUsage) => {
	return aiUsage?.customResponseCount?.limit ?? null
})

const getShouldDisplayLimitUpsell = createSelector([getAiUsage], (aiUsage) => {
	const limit = aiUsage?.customResponseCount?.limit ?? null
	const used = aiUsage?.customResponseCount?.used ?? null
	if (limit === 0) return true
	if (limit == null || used == null) return false
	return used >= limit
})

const getCustomResponseCountLimits = createSelector([getAiUsage], (aiUsage) => {
	const limit = aiUsage?.customResponseCount?.limit ?? null
	const count = aiUsage?.customResponseCount?.used ?? 0

	return {
		limit,
		count,
	}
})

const getAiSourcesRemainingDataFeeds = createSelector([getAiUsage], (aiUsage) => {
	const limit = aiUsage?.sourceFeedData.limit ?? 0
	const used = aiUsage?.sourceFeedData.used ?? 0
	const remainingData = limit - used
	return remainingData > 0 ? remainingData : 0
})

const getCanAddSource = createSelector([getAiSourcesDataLimits, getAiSourcesCountLimits], (dataLimits, countLimits) => {
	const canAddSourceType = (type: SourceFormType) => {
		const isCountReached = countLimits.limits[type].isReached
		const isDataReached = dataLimits.limits[type].isReached
		return !isCountReached && !isDataReached
	}

	return canAddSourceType(SourceFormType.Web) || canAddSourceType(SourceFormType.Xml)
})

const getAiTrainingEntriesLimits = createSelector([getAiUsage], (aiUsage): FeatureUsageLimitsType => {
	const count = aiUsage?.customResponseCount.used ?? 0
	const limit = aiUsage?.customResponseCount.limit ?? 0

	return calculateUsageLimits(count, limit, ['75', '90'])
})

const getAiSourcesOnboardingCondition = createSelector(
	[getAiUsage, getAiSourcesCountLimits],
	(aiUsage, sourcesLimits) => {
		return !((aiUsage?.sourceWebsites.used ?? 0) > 0) && !sourcesLimits.limits[SourceFormType.Xml].isReached
	},
)

const shouldShowAiChecklistBanner = createSelector([getAiChecklistUsage], (aiChecklistUsage) => {
	if (!aiChecklistUsage) return false

	return !aiChecklistUsage.isBonusApplied
})

export const usageSelectors = {
	getAiUsage,
	getMonthlyServedLimits,
	getChatbotConversationsLimits,
	getAiConversationsLimits,
	shouldShowChatbotConversationsLimitAlert,
	getAiReplyAssistLimits,
	getBonusAiConversationsLimits,
	getAiSourcesCountLimits,
	getIsAiPreviewLimitReached,
	getAiSourcesDataLimits,
	getAiSourcesDataLimitsInB,
	getAiTrainingEntriesLimit,
	getCustomResponseCountLimits,
	getShouldDisplayLimitUpsell,
	getAiSourcesLimitDataFeeds,
	getAiSourcesRemainingDataFeeds,
	getCanAddSource,
	getAiTrainingEntriesLimits,
	getAiSourcesOnboardingCondition,
	getAiChecklistUsage,
	getIsFetchingAiChecklist,
	shouldShowAiChecklistBanner,
	getIsAiChecklistCompleted,
	getIsAiChecklistGettingBonusConversations,
	getCenterActiveTab,
	getCenterAiTabExpanded,
}
