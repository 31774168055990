export { FeatureUsage, FeatureUsageInfo, FeatureUsageName } from '@smartsupp/core-client-api'

export enum FeatureFlag {
	DisableLegacyTriggersUI = 'disable-legacy-triggers-ui',
	AiChatbotsCustomization = 'ai-chatbots-custom-data',
}

export type FeatureFlags = Partial<Record<FeatureFlag, boolean>>

export type PublicFeatureFlag = {
	type: FeatureFlag
	name: string
	description: string
}

export type SelectedPublicFeatureFlag = PublicFeatureFlag & {
	isEnabled: boolean
}
