import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useBreakpointValue, useDisclosure } from '@chakra-ui/react'
import { mdiSpeedometer } from '@mdi/js'

import { UserPreference } from 'shared/constants'
import { loadUserPreference, storeUserPreference, TranslationService as T } from 'shared/services'
import { usageSharedSelectors } from 'modules/usage/sharedSelectors'

import { BadgeDot } from 'shared/components/core/Badge'
import { Box } from 'shared/components/core/Box'
import { CModal, CModalBody, CModalCloseButton, CModalContent, CModalOverlay } from 'shared/components/core/CModal'
import { CPopover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from 'shared/components/core/CPopover'
import { Flex } from 'shared/components/core/Flex'
import { Tooltip } from 'shared/components/core/Tooltip'

import SidebarMenuIcon from '../sidebarMenu/SidebarMenuIcon'

import SidebarUsageCenterContent from './SidebarUsageCenterContent'

type SidebarUsageCenterButtonProps = {
	shouldShowDot: boolean
	onClick?: () => void
}

const SidebarUsageCenterButton = ({ shouldShowDot, onClick }: SidebarUsageCenterButtonProps) => (
	<Box px="10px">
		<Box as="button" data-testid="sidebar-usage-center" id="sidebar-usage-center" w="full" onClick={onClick}>
			<Tooltip content={T.translate('usageCenter.title')} placement="right" closeOnClick offset={[0, 20]}>
				<Flex
					transition="background-color 200ms"
					_hover={{ bgColor: 'coal.800' }}
					borderRadius={2}
					alignItems="center"
					justifyContent="center"
					p="14px"
				>
					<Box position="relative">
						{shouldShowDot && (
							<BadgeDot
								boxShadow="shadow12"
								position="absolute"
								top="-6px"
								right="-6px"
								data-testid="badge-dot"
								backgroundColor="red.600"
								transition="box-shadow .2s ease"
							/>
						)}
						<SidebarMenuIcon icon={mdiSpeedometer} />
					</Box>
				</Flex>
			</Tooltip>
		</Box>
	</Box>
)

SidebarUsageCenterButton.displayName = 'SidebarUsageCenterButton'

const SidebarUsageCenter = () => {
	const shouldShowMobile = useBreakpointValue({ base: true, sm: false })
	const shouldShowWarning = useSelector(usageSharedSelectors.getShouldShowUsageCenterWarningDot)
	const [shouldHideHighlight, setShouldHideHighlight] = useState(
		loadUserPreference(UserPreference.UsageCenterHideHighlight) || false,
	)
	const { isOpen, onOpen, onClose } = useDisclosure()

	const shouldShowDot =
		shouldShowWarning.livechat || shouldShowWarning.ai || shouldShowWarning.automations || !shouldHideHighlight

	const handleOpen = () => {
		setShouldHideHighlight(true)
		storeUserPreference(UserPreference.UsageCenterHideHighlight, true)
	}

	if (shouldShowMobile) {
		return (
			<>
				<SidebarUsageCenterButton shouldShowDot={shouldShowDot} onClick={onOpen} />
				<CModal isOpen={isOpen} onClose={onClose}>
					<CModalOverlay />
					<CModalContent>
						<CModalCloseButton top={0.5} right={0.5} zIndex={10} />
						<CModalBody px={0} pt={2}>
							<SidebarUsageCenterContent />
						</CModalBody>
					</CModalContent>
				</CModal>
			</>
		)
	}

	return (
		<CPopover variant="light" placement="right-end" onOpen={handleOpen}>
			<PopoverTrigger>
				<Box>
					<SidebarUsageCenterButton shouldShowDot={shouldShowDot} />
				</Box>
			</PopoverTrigger>
			<PopoverContent width="auto">
				<PopoverArrow />
				<PopoverBody p={0} color="gray.800" overflowY="auto" maxH="100dvh">
					<Box maxW="530px">
						<SidebarUsageCenterContent />
					</Box>
				</PopoverBody>
			</PopoverContent>
		</CPopover>
	)
}

export default SidebarUsageCenter
