import { useSelector } from 'react-redux'
import { mdiChatOutline, mdiMessageFlashOutline, mdiRobotOutline } from '@mdi/js'

import { TranslationService as T } from 'shared/services'
import { autoMessagesSelectors } from 'modules/autoMessages'
import { chatbotSelectors } from 'modules/chatbots'
import { packageSelectors } from 'modules/package'
import { usageSelectors } from 'modules/usage'
import { usageSharedSelectors } from 'modules/usage/sharedSelectors'

import UsageCenterExpirationTag from '../parts/UsageCenterExpirationTag'
import UsageCenterLimitReset from '../parts/UsageCenterLimitReset'
import UsageCenterOverLimit from '../parts/UsageCenterOverLimit'
import UsageCenterPackageButton from '../parts/UsageCenterPackageButton'
import UsageCenterPackageDescription from '../parts/UsageCenterPackageDescription'
import UsageCenterProgress from '../parts/UsageCenterProgress'
import UsageCenterRow from '../parts/UsageCenterRow'
import UsageCenterSection from '../parts/UsageCenterSection'
import UsageCenterTab from '../parts/UsageCenterTab'
import UsageCenterTabPanel from '../parts/UsageCenterTabPanel'

const UsageCenterAutomationsTab = () => {
	const shouldShowWarning = useSelector(usageSharedSelectors.getShouldShowUsageCenterWarningDot)

	return (
		<UsageCenterTab showWarningDot={shouldShowWarning.automations}>
			{T.translate('headerBar.automations')}
		</UsageCenterTab>
	)
}

const ChatbotConversationsRow = () => {
	const chatbotConversationsLimits = useSelector(usageSelectors.getChatbotConversationsLimits)
	const { limit, count, isOverLimit, isOverThreshold75, isOverThreshold90, isOverThreshold95 } =
		chatbotConversationsLimits
	const isUnlimited = limit === null

	const description = isUnlimited ? null : isOverLimit ? (
		<UsageCenterOverLimit type="chatbotConversations" limit={limit} />
	) : (
		<UsageCenterLimitReset />
	)

	return (
		<UsageCenterRow
			title={T.translate('features.chatbotConversations')}
			icon={mdiChatOutline}
			description={description}
			body={
				<UsageCenterProgress
					value={count}
					limit={limit}
					isUnlimited={isUnlimited}
					isDanger={isOverLimit}
					isWarning={isOverThreshold75 || isOverThreshold90 || isOverThreshold95}
				/>
			}
		/>
	)
}

const ChatbotsLimitRow = () => {
	const chatbotsLimits = useSelector(chatbotSelectors.getChatbotLimits)
	const { limit, count, isOverLimit, isOverThreshold75, isOverThreshold90 } = chatbotsLimits
	const isUnlimited = limit === null
	const shouldShowDesc = !isUnlimited && limit > 0 && isOverLimit

	return (
		<UsageCenterRow
			title={T.translate('general.chatbots')}
			icon={mdiRobotOutline}
			description={shouldShowDesc && <UsageCenterOverLimit type="chatbots" limit={limit} />}
			body={
				<UsageCenterProgress
					value={count}
					limit={limit}
					isUnlimited={isUnlimited}
					isDanger={isOverLimit}
					isWarning={isOverThreshold75 || isOverThreshold90}
				/>
			}
		/>
	)
}

const AutoMessagesRow = () => {
	const autoMessagesLimits = useSelector(autoMessagesSelectors.getAutoMessagesLimits)
	const { limit, count } = autoMessagesLimits
	const isUnlimited = limit === null
	const shouldShowOverLimit = !isUnlimited && count >= limit

	return (
		<UsageCenterRow
			title={T.translate('headerBar.autoMessages')}
			icon={mdiMessageFlashOutline}
			description={shouldShowOverLimit && <UsageCenterOverLimit type="autoMessages" limit={limit} />}
			body={<UsageCenterProgress value={count} limit={limit} isUnlimited={isUnlimited} />}
		/>
	)
}

const UsageCenterAutomationsTabPanel = () => {
	const packageInfo = useSelector(packageSelectors.getPackageInfo)
	const shouldShowLimitSection = !packageInfo?.isExpired

	return (
		<UsageCenterTabPanel>
			<UsageCenterSection heading={T.translate('usageCenter.packageInfo')}>
				<UsageCenterRow
					title={packageInfo?.formattedName}
					titlePostfix={<UsageCenterExpirationTag />}
					description={<UsageCenterPackageDescription />}
					body={<UsageCenterPackageButton />}
				/>
			</UsageCenterSection>
			{shouldShowLimitSection && (
				<UsageCenterSection heading={T.translate('usageCenter.currentUsage')}>
					<ChatbotConversationsRow />
					<ChatbotsLimitRow />
					<AutoMessagesRow />
				</UsageCenterSection>
			)}
		</UsageCenterTabPanel>
	)
}

export { UsageCenterAutomationsTab, UsageCenterAutomationsTabPanel }
