import { useSelector } from 'react-redux'
import moment from 'moment'

import { TranslationService as T } from 'shared/services'
import { packageSelectors } from 'modules/package'

import { IntlDate } from 'components/intl'
import { Text } from 'shared/components/core/Text'

const UsageCenterLimitReset = () => {
	const packageInfo = useSelector(packageSelectors.getPackageInfo)
	const firstDayNextMonth = moment().add(1, 'month').startOf('month').format()

	if (!packageInfo) return null

	const { billingPeriodEndDate, isTrial } = packageInfo

	const text = isTrial ? 'usageCenter.limitResetTrial' : 'usageCenter.limitReset'
	const date = isTrial && billingPeriodEndDate ? billingPeriodEndDate : firstDayNextMonth

	return (
		<Text color="gray.500" fontSize="sm">
			{T.translate(text)} <IntlDate value={date} />
		</Text>
	)
}

export default UsageCenterLimitReset
