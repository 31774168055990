import { createSelector } from '@reduxjs/toolkit'

import { agentsSelectors } from 'modules/agents'
import { chatbotSelectors } from 'modules/chatbots'
import { packageSelectors } from 'modules/package'

import { usageSelectors } from './slice'
import { UsageCenterTabs } from './types'

const getShouldShowUsageCenterWarningDot = createSelector(
	[
		packageSelectors.getPackageInfo,
		usageSelectors.getMonthlyServedLimits,
		usageSelectors.getAiReplyAssistLimits,
		usageSelectors.getAiConversationsLimits,
		usageSelectors.getChatbotConversationsLimits,
		chatbotSelectors.getChatbotLimits,
		agentsSelectors.getAgentsLimits,
	],
	(
		packageInfo,
		livechatConversationsUsage,
		aiReplyAssistantUsage,
		aiConversationsUsage,
		chatbotConversationsUsage,
		chatbotUsage,
		agentsUsage,
	): Record<UsageCenterTabs, boolean> => {
		const isLivechatLimitReached =
			livechatConversationsUsage.isOverLimit ||
			(aiReplyAssistantUsage.isOverLimit && !packageInfo?.isPro) ||
			agentsUsage.isOverLimit
		const isAiLimitReached = aiConversationsUsage.isOverLimit || chatbotConversationsUsage.isOverLimit || false
		const isAutomationsLimitReached = chatbotUsage.isOverLimit || false

		return {
			[UsageCenterTabs.Livechat]: isLivechatLimitReached,
			[UsageCenterTabs.Ai]: isAiLimitReached,
			[UsageCenterTabs.Automations]: isAutomationsLimitReached,
		}
	},
)

export const usageSharedSelectors = {
	getShouldShowUsageCenterWarningDot,
}
