import { ExtendedSourceType } from 'shared/models/Source'

export enum AiOnboardingStep {
	Introduction = 'introduction',
	Survey = 'survey',
	SourceWeb = 'source-web',
	SourceFeed = 'source-feed',
	Categories = 'categories',
	Identity = 'identity',
	Preview = 'preview',
}

export interface AiOnboardingSourcesData {
	url: string
	type: ExtendedSourceType
	currency?: string
}

export enum AiOnboardingSurveyOptions {
	Web = 'web',
	Eshop = 'eshop',
}
