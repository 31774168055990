// TODO use types from mira-types spec
import { Sources } from '@smartsupp/mira-types'

export enum SourceStatus {
	Creating = 'creating',
	Updating = 'updating',
	Ready = 'ready',
	Failed = 'failed',
	Warning = 'warning',
	Pending = 'pending',
}

export interface SourceStatusErrors {
	code: `${Sources.ErrorCodes}`
	reason: string
	times: string
}

export enum ExtendedSourceType {
	WEB = 'web',
	XML = 'xml',
	WebsiteCrawl = 'website_crawl',
	HeurekaFeed = 'heureka_feed',
	GoogleFeed = 'google_feed',
}

interface SourceParser {
	url: string
	type: ExtendedSourceType
	currency?: string
}

export interface Source {
	id: number
	accountId: string
	title: string
	parser: SourceParser
	status: SourceStatus
	errors?: SourceStatusErrors[]
	itemsCount?: number
	publishedAt?: string
	categories: SourceCategory[]
	autoUpdate: {
		enabled: boolean
		frequencyInSeconds: number
	}
}

export type SourceCategory = {
	name: string
	items: number
	size: number
}

export type SourceContent = {
	sourceId: number
	content: string
}

export interface CreateSourceBody {
	title: Source['title']
	parser: Source['parser']
	autoUpdate: Source['autoUpdate']
}

export interface PatchSourceBody {
	title?: Source['title']
	parser?: Source['parser']
	autoUpdate?: Source['autoUpdate']
}

export type SourceCheckResult = {
	fileValid: boolean
	limitReached: boolean
	size: number
	categoryList: SourceCategory[]
}
